import { Popover } from 'antd';
import { WorkflowStatus } from '../TreatmentPlanRequestTable/types';

interface Props {
  insightName: string;
  insightProperty?: string | number | null | any[];
  workflowStatus: WorkflowStatus;
}

const InsightsMissingPopover: React.FC<Props> = ({
  insightName,
  insightProperty,
  workflowStatus,
  children,
}) => {
  const content =
    'Error: Something went wrong in calculating the ' +
    insightName +
    ' insights.';
  return workflowStatus.status === 'completed' && !insightProperty ? (
    <Popover content={content}>{children}</Popover>
  ) : (
    <div>{children}</div>
  );
};

export default InsightsMissingPopover;
