import { WorkflowResults } from 'solid-workflow-service-typescript-axios';
import React, { CSSProperties, useState } from 'react';
import { Card, Typography, Button } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import ArithmeticHipKneeAnkleModal from './ArithmeticHipKneeAnkleModal';

const { Title } = Typography;
interface Props {
  workflowResults: WorkflowResults;
  widgetVisible: boolean;
  style?: CSSProperties;
}

function ArithmeticHipKneeAnkle({
  workflowResults: insightsResults,
  widgetVisible,
  style,
}: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  if (widgetVisible) {
    return (
      <Card
        style={style}
        title={<Title level={5}>Arithmetic Hip-Knee-Ankle (aHKA)</Title>}
        extra={
          <div>
            <Button
              icon={<InfoCircleOutlined style={{ fontSize: '1.2rem' }} />}
              type="text"
              onClick={() => setIsModalOpen(true)}
            ></Button>
          </div>
        }
      >
        <Title level={4} style={{ margin: 0 }}>
          {toDisplayArthimeticHipKneeAnkle(
            insightsResults.fullLegArithmeticHipKneeAnkle
          )}
        </Title>
        <ArithmeticHipKneeAnkleModal
          modalOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      </Card>
    );
  } else {
    return <div />;
  }
}
export function toDisplayArthimeticHipKneeAnkle(aHKA?: number | null) {
  {
    /* Negative = Valgus, Positive = Varus */
  }
  let ahkaDisplay = '';
  if (aHKA) {
    ahkaDisplay = `${aHKA > 0 ? 'Varus' : 'Valgus'} ${Math.abs(aHKA)}°`;
  }
  return ahkaDisplay;
}

export default ArithmeticHipKneeAnkle;
