import { useContext, useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import io from 'socket.io-client';
import {
  TREATMENT_PLAN_REQUESTS_QUERY_KEY,
  TREATMENT_PLAN_REQUEST_QUERY_KEY,
} from '../../utils/consts';
import config from '../../config';
import { useAccessToken } from 'osep-react-antd';
import { TenantContext } from '../TenantContextProvider';
import useSilentRequest from '../../hooks/useSilentRequest';

function InsightStatusWebSocket() {
  const queryClient = useQueryClient();
  const tenantContext = useContext(TenantContext);
  const tenantId = tenantContext?.tenant?.id;
  const silentRequest = useSilentRequest();
  const aquireAccessToken = useAccessToken(silentRequest);

  const [accessToken, setAccessToken] = useState<string>();

  useEffect(() => {
    aquireAccessToken().then(({ accessToken }) => setAccessToken(accessToken));
  }, [aquireAccessToken]);

  useEffect(() => {
    if (!accessToken) {
      return;
    }

    const invalidateTreatmentPlanRequestQueries = (
      treatpmentPlanReqestId: string
    ) => {
      queryClient.invalidateQueries([
        TREATMENT_PLAN_REQUESTS_QUERY_KEY,
        tenantId,
      ]);
      queryClient.invalidateQueries([
        TREATMENT_PLAN_REQUEST_QUERY_KEY,
        tenantId,
        treatpmentPlanReqestId,
      ]);
    };

    const socket = io(config.solidBasePath, {
      auth: { accessToken },
    });

    socket.on('connect_error', (error) => {
      console.info('[WebSocket] Connect Error:', error);
    });

    socket.on('connect', () => {
      console.info('[WebSocket] Connected.');

      socket.io.on('reconnect_attempt', (data) => {
        console.info('[WebSocket] Trying to reconnect...', data);
      });

      socket.io.on('reconnect', (data) => {
        console.info('[WebSocket] Reconnected.', data);
      });

      socket.on('connect_error', (error) => {
        console.info('[WebSocket] (Re)Connect Error:', error);
        if (error.message === 'Connection rejected by server') {
          aquireAccessToken().then(({ accessToken }) =>
            setAccessToken(accessToken)
          );
        }
      });

      socket.on('disconnect', (reason) => {
        console.info('[WebSocket] Disconnected:', reason);
      });
    });

    socket.on('workflow_status_change', (data) => {
      console.log(
        `[WebSocket] Workflow status of TPR '${data.treatment_plan_request_id}' changed to '${data.status}'`
      );
      invalidateTreatmentPlanRequestQueries(data.treatment_plan_request_id);
    });

    socket.on('consent_change', (data) => {
      console.log(
        `[WebSocket] Patient consent of TPR '${
          data.treatment_plan_request_id
        }' changed to '${data.status ? 'granted' : 'not-granted'}'`
      );
      invalidateTreatmentPlanRequestQueries(data.treatment_plan_request_id);
    });

    return () => {
      socket.disconnect();
    };
  }, [accessToken, aquireAccessToken, queryClient, tenantId]);

  return <></>;
}

export default InsightStatusWebSocket;
