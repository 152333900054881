function minutesToDisplayHours(predicteMinutes: number): string {
  const two_digit = (value: number) =>
    value.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });

  const hours = Math.floor(predicteMinutes / 60);
  const minutes = Math.floor(predicteMinutes - hours * 60);

  return `${two_digit(hours)}:${two_digit(minutes)} h`;
}

export default minutesToDisplayHours;
