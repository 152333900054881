import ApplicationHeader from '../components/ApplicationHeader';
import styles from './CenteredPageLayout.module.css';

const CenteredPageLayout: React.FC = ({ children }) => {
  return (
    <div className={styles.container}>
      <div className={styles.headerItem}>
        <ApplicationHeader />
      </div>
      <div className={styles.contentItem}>
        <div className={styles.center}>{children}</div>
      </div>
    </div>
  );
};

export default CenteredPageLayout;
