import { Typography, Modal } from 'antd';
const { Title } = Typography;

interface Props {
  modalOpen: boolean;
  onClose: any;
}
const infotext = (
  <span>
    The aHKA (arithmetic hip-knee-ankle) angle can be used to estimate the
    constitutional (pre-arthritic) limb alignment in arthritic patients. The
    aHKA angle is calculated as the difference between the medial proximal
    tibial angle (MPTA) and lateral distal femoral angle (LDFA).
  </span>
);

function ArithmeticHipKneeAnkleModal({ modalOpen, onClose }: Props) {
  return (
    <Modal
      title={<Title level={3}>Arithmetic Hip Knee Ankle</Title>}
      open={modalOpen}
      onCancel={onClose}
      footer={null}
      width={1000}
    >
      <Title level={5} style={{ fontWeight: 375 }}>
        {infotext}
      </Title>
    </Modal>
  );
}

export default ArithmeticHipKneeAnkleModal;
