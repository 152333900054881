import { Card, Col, Row, Spin } from 'antd';
import { useEffect, useState } from 'react';
import {
  TreatmentPlanRequestEntity,
  WorkflowExecutionStatusEnum,
} from 'solid-workflow-service-typescript-axios';
import findLatestWorkflowExection from '../../utils/findLatestWorkflowExecution';
import { WorkflowStatus } from '../TreatmentPlanRequestTable/types';
import { toDisplayWorkflowStatus } from '../TreatmentPlanRequestTable/useTableRecords';
import PostOperativeForm from './PostOperativeForm';
import PreOperativeForm from './PreOperativeForm';
import { FormState } from './types';
import InsightsSummaryCard from './InsightsSummaryCard';

interface Props {
  treatmentPlanRequest: TreatmentPlanRequestEntity;
}

function TreatmentPlanRequestDetails({ treatmentPlanRequest }: Props) {
  const [preOperativeFormState, setPreOperativeFormState] =
    useState<FormState>();
  const [postOperativeFormState, setPostOperativeFormState] =
    useState<FormState>();
  const [activeTabKey, setActiveTabKey] = useState('pre-operative-data');

  const latestWorkflowExecution = findLatestWorkflowExection(
    treatmentPlanRequest?.workflowExecutions
  );
  const workflowStatus: WorkflowStatus = {
    displayValue: toDisplayWorkflowStatus(
      latestWorkflowExecution?.status ?? WorkflowExecutionStatusEnum.NotStarted
    ),
    status:
      latestWorkflowExecution?.status ?? WorkflowExecutionStatusEnum.NotStarted,
  };

  const isSaving =
    preOperativeFormState === 'saving' || postOperativeFormState === 'saving';

  useEffect(() => {
    if (workflowStatus.status === WorkflowExecutionStatusEnum.Completed) {
      setActiveTabKey('post-operative-data');
    }
  }, [workflowStatus.status]);

  return (
    <>
      <Row gutter={[16, 32]}>
        <Col xs={24} xl={12}>
          <Card
            style={{ height: '98%', width: '93%', marginLeft: '1vw' }}
            actions={[]}
            activeTabKey={activeTabKey}
            tabList={[
              {
                key: 'pre-operative-data',
                tab: 'Pre-Operative Information',
              },
              {
                key: 'post-operative-data',
                tab: 'Post-Operative Information',
              },
            ]}
            onTabChange={(tabKey) => setActiveTabKey(tabKey)}
            tabBarExtraContent={
              isSaving && (
                <>
                  <Spin size="small" />
                  <span style={{ marginLeft: 8 }}>Saving...</span>
                </>
              )
            }
          >
            {activeTabKey === 'pre-operative-data' && (
              <PreOperativeForm
                treatmentPlanRequest={treatmentPlanRequest}
                onStateChange={setPreOperativeFormState}
              />
            )}

            {activeTabKey === 'post-operative-data' && (
              <PostOperativeForm
                treatmentPlanRequest={treatmentPlanRequest}
                onStateChange={setPostOperativeFormState}
              />
            )}
          </Card>
        </Col>
        <Col xs={24} xl={12}>
          <InsightsSummaryCard
            treatmentPlanRequest={treatmentPlanRequest}
            workflowStatus={workflowStatus}
          />
        </Col>
      </Row>
    </>
  );
}
export default TreatmentPlanRequestDetails;
