import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import React, { CSSProperties } from 'react';
interface Props {
  facing: string;
  width?: number;
}
function ChartArrow({ facing, width }: Props) {
  let lineStyle: CSSProperties = {
    width: (width ?? 50) - 18,
    background: 'black',
    height: '0.1rem',
    float: facing === 'left' ? 'right' : 'left',
    position: 'relative',
    top: 12,
  };
  if (facing === 'left') {
    lineStyle.right = 12;
    return (
      <div style={{ width: width ?? 50, marginRight: -8 }}>
        <LeftOutlined style={{ fontSize: '1.1rem', marginTop: 4 }} />
        <div style={lineStyle}></div>
      </div>
    );
  } else {
    lineStyle.left = 12;
    return (
      <div style={{ width: width ?? 50, marginLeft: -8, marginRight: -5 }}>
        <RightOutlined style={{ fontSize: '1.1rem', marginTop: 4 }} />
        <div style={lineStyle}></div>
      </div>
    );
  }
}
export default ChartArrow;
