import { EyeOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import {
  TreatmentPlanRequestEntity,
  WorkflowExecutionStatusEnum,
} from 'solid-workflow-service-typescript-axios';
import findLatestWorkflowExection from '../../utils/findLatestWorkflowExecution';

interface Props {
  treatmentPlanRequest: TreatmentPlanRequestEntity;
}

function OpenKneeInsightsButton({ treatmentPlanRequest }: Props) {
  const navigate = useNavigate();

  const latestWorkflowExecution = findLatestWorkflowExection(
    treatmentPlanRequest?.workflowExecutions
  );
  const treatmentPlanRequestId = treatmentPlanRequest.metadata?.requestId;
  const disabled =
    latestWorkflowExecution?.status !== WorkflowExecutionStatusEnum.Completed;

  return (
    <Button
      type="primary"
      onClick={() => treatmentPlanRequestId && navigate('./knee-insights/')}
      icon={<EyeOutlined />}
      disabled={disabled}
    >
      Open Knee Insights
    </Button>
  );
}

export default OpenKneeInsightsButton;
