import {
  faCalendarDays,
  faHospital,
  faUser,
} from '@fortawesome/free-regular-svg-icons';
import {
  faHashtag,
  faSyringe,
  faUserDoctor,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Col, Empty, Row, Spin } from 'antd';
import moment from 'moment';
import { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ApplicationHeader from '../components/ApplicationHeader';
import PageContent from '../components/PageContent';
import PageHeader from '../components/PageHeader';
import { TenantContext } from '../components/TenantContextProvider';
import TreatmentPlanRequestDetails from '../components/TreatmentPlanRequestDetails/TreatmentPlanRequestDetails';
import config from '../config';
import usePatientsQuery from '../hooks/queries/usePatientsQuery';
import useTreatmentPlanRequestQuery from '../hooks/queries/useTreatmentPlanRequestQuery';
import useLocaleSettings from '../hooks/useLocaleSettings';
import CenteredPageLayout from './CenteredPageLayout';

function TreatmentPlanRequestDetailsPage() {
  const { treatmentPlanRequestId } = useParams();
  const navigate = useNavigate();
  const tenantContext = useContext(TenantContext);

  const {
    data: treatmentPlanRequest,
    isLoading: treatmentPlanRequstIsLoading,
  } = useTreatmentPlanRequestQuery(treatmentPlanRequestId ?? '');

  const locale = useLocaleSettings();
  const procedureDisplayName =
    config.procedureDisplayNames.find(
      (procedure) =>
        procedure.name ===
        treatmentPlanRequest?.treatment?.application?.applicationType
    )?.displayName ?? 'N/A';

  const { data: patients, isLoading: patientsAreLoading } = usePatientsQuery(
    treatmentPlanRequest?.patientId ? [treatmentPlanRequest?.patientId] : [],
    !!treatmentPlanRequest
  );

  if (treatmentPlanRequstIsLoading || patientsAreLoading) {
    return (
      <CenteredPageLayout>
        <Spin />
      </CenteredPageLayout>
    );
  }

  const patient = patients
    ? treatmentPlanRequest?.patientId
      ? patients[treatmentPlanRequest?.patientId]
      : undefined
    : undefined;

  if (!treatmentPlanRequest || !patient) {
    return (
      <CenteredPageLayout>
        <Empty />
      </CenteredPageLayout>
    );
  }

  const infos = [
    {
      icon: <FontAwesomeIcon icon={faHashtag} />,
      title: 'TPR ID',
      value: treatmentPlanRequest.externalReference,
    },
    {
      icon: <FontAwesomeIcon icon={faUser} />,
      title: 'Patient',
      value: [patient.firstName, patient.lastName].join(' '),
    },
    {
      icon: <FontAwesomeIcon icon={faHospital} />,
      title: 'Hospital',
      value: treatmentPlanRequest.treatment?.hospitalName,
    },
    {
      icon: <FontAwesomeIcon icon={faSyringe} />,
      title: 'Procedure',
      value: procedureDisplayName,
    },
    {
      icon: <FontAwesomeIcon icon={faCalendarDays} />,
      title: 'Surgery Date',
      value: moment(treatmentPlanRequest?.treatment?.surgeryDateTime).format(
        locale.dateFormat
      ),
    },
    {
      icon: <FontAwesomeIcon icon={faUserDoctor} />,
      title: 'Surgeon',
      value: [
        treatmentPlanRequest?.treatment?.surgeonName?.firstName,
        treatmentPlanRequest?.treatment?.surgeonName?.lastName,
      ].join(' '),
    },
  ];

  return (
    <div style={{ background: '#f4f4f4' }}>
      <ApplicationHeader />
      <PageHeader
        onBack={() => navigate(`/${tenantContext?.tenant?.id}`)}
        title="Request Details"
      >
        <Row
          gutter={[16, 32]}
          style={{
            marginTop: '0.5vw',
            marginRight: '1vw',
            marginLeft: '0.6vw',
          }}
        >
          {infos.map((info) => (
            <Col xs={24} sm={12} lg={4} key={info.title}>
              <Card
                size="small"
                extra={info.icon}
                title={info.title}
                bordered={false}
              >
                {info.value}
              </Card>
            </Col>
          ))}
        </Row>
      </PageHeader>
      <PageContent>
        <TreatmentPlanRequestDetails
          treatmentPlanRequest={treatmentPlanRequest}
        />
      </PageContent>
    </div>
  );
}

export default TreatmentPlanRequestDetailsPage;
