import { Checkbox, Col, Divider, Form, InputNumber, Row, Select } from 'antd';
import convert from 'convert';
import { useContext, useEffect, useRef, useState } from 'react';
import {
  PreOperativeData,
  TreatmentPlanRequestEntity,
} from 'solid-workflow-service-typescript-axios';

import { notifyUser } from 'osep-react-antd';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import usePreOperativeDataMutation from '../../hooks/mutations/usePreOperativeDataMutation';
import GenerateInsightsButton from './GenerateInsightsButton';

import { LocaleSettings } from '../../utils/getLocalSettings';
import AutoSave from '../AutoSave';
import { TenantContext } from '../TenantContextProvider';
import { FormState } from './types';

interface Props {
  treatmentPlanRequest?: TreatmentPlanRequestEntity;
  onStateChange: (state: FormState) => void;
}

type FormValues = {
  height?: number;
  weight?: number;
  patientConsentGranted?: boolean;
};

function PreOperativeForm({ treatmentPlanRequest, onStateChange }: Props) {
  const tenantContext = useContext(TenantContext);

  const [isImageQualityConfirmed, setIsImageQualityConfirmed] = useState(false);

  const [selectedWeightUnit, setSelectedWeightUnit] =
    useState<LocaleSettings['weightUnit']>('kg');
  const [selectedHeightUnit, setSelectedHeightUnit] =
    useState<LocaleSettings['heightUnit']>('cm');

  const selectedWeightUnitRef = useRef<LocaleSettings['weightUnit']>();
  const selectedHeightUnitRef = useRef<LocaleSettings['heightUnit']>();

  selectedWeightUnitRef.current = selectedWeightUnit;
  selectedHeightUnitRef.current = selectedHeightUnit;

  const defaultHeightInMeters =
    treatmentPlanRequest?.preOperativeData?.heightInMeters;
  const defaultWeightInKilos =
    treatmentPlanRequest?.preOperativeData?.weightInKilos;

  const defaultValues: FormValues = {
    patientConsentGranted: treatmentPlanRequest?.patientConsentGranted,
    height: defaultHeightInMeters
      ? parseFloat(convert(defaultHeightInMeters, 'm').to('cm').toFixed(1))
      : undefined,
    weight: defaultWeightInKilos
      ? parseFloat(defaultWeightInKilos.toFixed(1))
      : undefined,
  };

  const form = useForm({ defaultValues });

  const savePreOperativeData = usePreOperativeDataMutation();

  const {
    control,
    resetField,
    getValues,
    reset,
    formState: { isDirty, isSubmitting, isSubmitSuccessful },
  } = form;

  const [submittedData, setSubmittedData] = useState<FormValues>();

  const saveForm = (data: FormValues): Promise<void> => {
    const { weight, height, patientConsentGranted } = data;

    const treatmentPlanRequestId = treatmentPlanRequest?.metadata?.requestId;

    if (
      !treatmentPlanRequestId ||
      !selectedWeightUnitRef.current ||
      !selectedHeightUnitRef.current
    ) {
      return Promise.resolve();
    }

    // React Forms caches the callback.
    // Changing the state if the selected height and weight unit is therefore not reflected.
    // Using the state variables will cause always to use the first unit selected.
    // Use ref instead to always point to the current value.
    // see: https://stackoverflow.com/questions/57847594/react-hooks-accessing-up-to-date-state-from-within-a-callback

    const preOperativeData: PreOperativeData = {
      heightInMeters: height
        ? convert(height, selectedHeightUnitRef.current).to('m')
        : undefined,
      weightInKilos: weight
        ? convert(weight, selectedWeightUnitRef.current).to('kg')
        : undefined,
      patientConsentGranted: patientConsentGranted ?? false,
    };

    return savePreOperativeData
      .mutateAsync({
        treatmentPlanRequestId,
        preOperativeData,
      })
      .then(() => {
        setSubmittedData(data);
      })
      .catch(() => {
        reset(defaultValues);
        notifyUser(
          'error',
          `Could not save pre-operative data for ${treatmentPlanRequest?.externalReference}`
        );
      });
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({ ...submittedData }, { keepValues: true });
    }
  }, [isSubmitSuccessful, submittedData, reset]);

  const isSaving = isDirty || isSubmitting;

  useEffect(() => {
    onStateChange(isSaving ? 'saving' : 'saved');
  }, [isSaving, onStateChange]);

  const usePatientConsent = tenantContext?.tenant?.usePatientConsent ?? false;

  return (
    <>
      <FormProvider {...form}>
        <Form layout="vertical">
          <Row gutter={[16, 8]}>
            {usePatientConsent && (
              <Col xs={24}>
                <Controller
                  name="patientConsentGranted"
                  control={control}
                  render={({ field }) => (
                    <Form.Item>
                      <Checkbox {...field} checked={field.value} />
                      <label style={{ marginLeft: 8 }}>
                        Patient Consent Granted
                      </label>
                    </Form.Item>
                  )}
                />
              </Col>
            )}

            <Col xs={24} sm={12}>
              <Controller
                name="weight"
                control={control}
                render={({ field }) => (
                  <Form.Item label="Patient Weight">
                    <InputNumber
                      style={{ width: '60%' }}
                      addonAfter={
                        <Select
                          value={selectedWeightUnit}
                          onChange={(value: LocaleSettings['weightUnit']) => {
                            resetField('weight', {
                              defaultValue: parseFloat(
                                convert(
                                  Number(getValues().weight),
                                  selectedWeightUnit
                                )
                                  .to(value)
                                  .toFixed(1)
                              ),
                            });

                            setSelectedWeightUnit(value);
                          }}
                        >
                          <Select.Option value="kg">kg</Select.Option>
                          <Select.Option value="lb">lbs</Select.Option>
                        </Select>
                      }
                      {...field}
                      min={0}
                    />
                  </Form.Item>
                )}
              />
            </Col>
            <Col xs={24} sm={12}>
              <Controller
                name="height"
                control={control}
                render={({ field }) => (
                  <Form.Item label="Patient Height">
                    <InputNumber
                      style={{ width: '60%' }}
                      addonAfter={
                        <Select
                          value={selectedHeightUnit}
                          onChange={(value: LocaleSettings['heightUnit']) => {
                            resetField('height', {
                              defaultValue: parseFloat(
                                convert(
                                  Number(getValues().height),
                                  selectedHeightUnit
                                )
                                  .to(value)
                                  .toFixed(1)
                              ),
                            });
                            setSelectedHeightUnit(value);
                          }}
                        >
                          <Select.Option value="cm">cm</Select.Option>
                          <Select.Option value="in">in</Select.Option>
                        </Select>
                      }
                      {...field}
                      min={0}
                    />
                  </Form.Item>
                )}
              />
            </Col>
          </Row>

          <AutoSave onSubmit={saveForm} defaultValues={defaultValues} />
        </Form>
      </FormProvider>
      <Row>
        <Divider />
      </Row>
      <Row gutter={[16, 0]}>
        <Col xs={24}>
          <Form.Item shouldUpdate>
            <Checkbox
              checked={isImageQualityConfirmed}
              onChange={() =>
                setIsImageQualityConfirmed(!isImageQualityConfirmed)
              }
            />
            <label style={{ marginLeft: 10 }}>
              I confirm that I have checked the quality of the scan associated
              with this Treatment Plan Request.
            </label>
          </Form.Item>
        </Col>
        <Col xs={24} style={{ textAlign: 'center' }}>
          <Form.Item>
            <GenerateInsightsButton
              onWorkflowStart={() => setIsImageQualityConfirmed(false)}
              enabled={
                isImageQualityConfirmed &&
                !isSaving &&
                (!usePatientConsent ||
                  !!treatmentPlanRequest?.patientConsentGranted) &&
                !!treatmentPlanRequest?.preOperativeData?.heightInMeters &&
                !!treatmentPlanRequest?.preOperativeData?.weightInKilos
              }
              treatmentPlanRequestId={
                treatmentPlanRequest?.metadata?.requestId ?? ''
              }
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}

export default PreOperativeForm;
