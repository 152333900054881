import { Html, useProgress } from '@react-three/drei';
import { Progress } from 'antd';

function BoneModelLoadingIndicator() {
  const { progress } = useProgress();

  return (
    <Html center>
      <Progress
        percent={Math.round(progress)}
        status="active"
        type={'circle'}
      />
    </Html>
  );
}

export default BoneModelLoadingIndicator;
