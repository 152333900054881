import { useMemo } from 'react';
import {
  Patient,
  WorkflowExecutionStatusEnum,
} from 'solid-workflow-service-typescript-axios';
import config from '../../config';
import usePatientsQuery from '../../hooks/queries/usePatientsQuery';
import useTreatmentPlanRequestsQuery, {
  Filters,
} from '../../hooks/queries/useTreatmentPlanRequestsQuery';
import findLatestWorkflowExection from '../../utils/findLatestWorkflowExecution';
import minutesToDisplayHours from '../../utils/minutesToDisplayHours';
import toDisplayName from './toDisplayName';
import { TableRecord, WorkflowStatus } from './types';

export function toDisplayWorkflowStatus(
  workflowExecutionStatus: WorkflowExecutionStatusEnum
): WorkflowStatus['displayValue'] {
  switch (workflowExecutionStatus) {
    case WorkflowExecutionStatusEnum.Completed:
      return 'Completed';
    case WorkflowExecutionStatusEnum.Error:
      return 'Error';
    case WorkflowExecutionStatusEnum.InProgress:
      return 'In Progress';
    case WorkflowExecutionStatusEnum.NotStarted:
      return 'Not Started';
    default:
      return 'Not Started';
  }
}

function useTableRecords(filters: Filters) {
  const {
    isLoading: treatmentPlanRequestsAreLoading,
    data: treatmentPlanRequestsByIds,
  } = useTreatmentPlanRequestsQuery(filters);

  const { isLoading: patientsAreLoading, data: patientsByIds } =
    usePatientsQuery(
      treatmentPlanRequestsByIds
        ? Object.values(treatmentPlanRequestsByIds).map(
            (t) => t.patientId ?? ''
          )
        : [],
      !treatmentPlanRequestsAreLoading
    );

  const isLoading = treatmentPlanRequestsAreLoading || patientsAreLoading;

  return useMemo(() => {
    const tableRecords = Object.values(treatmentPlanRequestsByIds ?? {}).map(
      (treatmentPlanRequest): TableRecord => {
        const treatmentPlanRequestId =
          treatmentPlanRequest.metadata?.requestId ?? '';

        const patientId = treatmentPlanRequest.patientId ?? '';

        const patient: Patient | undefined = (patientsByIds ?? {})[patientId];

        const operativeSide = treatmentPlanRequest.treatment?.operativeSide;

        const workflowExecution = findLatestWorkflowExection(
          treatmentPlanRequest.workflowExecutions
        );

        const cementedPredictedSurgicalTimeInMinutes =
          workflowExecution?.results?.surgicalTimeCemented
            ?.predictedDurationInMinutes;

        const cementlessPredictedSurgicalTimeInMinutes =
          workflowExecution?.results?.surgicalTimeCementless
            ?.predictedDurationInMinutes;

        const workflowStatus: WorkflowStatus = {
          displayValue: toDisplayWorkflowStatus(
            workflowExecution?.status ?? WorkflowExecutionStatusEnum.NotStarted
          ),
          status:
            workflowExecution?.status ?? WorkflowExecutionStatusEnum.NotStarted,
          error: workflowExecution?.error ?? undefined,
        };

        return {
          treatmentPlanRequestId,
          patientId,
          externalReference: treatmentPlanRequest.externalReference ?? '',
          patientConsentGranted:
            treatmentPlanRequest.patientConsentGranted ?? false,
          hospitalName: treatmentPlanRequest.treatment?.hospitalName ?? '',
          patientName: toDisplayName(patient),
          primaryContactName: toDisplayName(
            treatmentPlanRequest.primaryContact
          ),
          cementedPredictedSurgicalTime: cementedPredictedSurgicalTimeInMinutes
            ? minutesToDisplayHours(cementedPredictedSurgicalTimeInMinutes)
            : undefined,
          cementlessPredictedSurgicalTime:
            cementlessPredictedSurgicalTimeInMinutes
              ? minutesToDisplayHours(cementlessPredictedSurgicalTimeInMinutes)
              : undefined,

          surgeonName: toDisplayName(
            treatmentPlanRequest.treatment?.surgeonName
          ),
          operativeSide:
            operativeSide?.replace(/^./, operativeSide[0].toUpperCase()) ?? '',
          applicationType:
            config.procedureDisplayNames.find(
              (procedure) =>
                procedure.name ===
                treatmentPlanRequest.treatment?.application?.applicationType
            )?.displayName ?? '',
          requestState:
            config.solidEligibleTprStates.find(
              (current) =>
                current.name ===
                treatmentPlanRequest.metadata?.commentedRequestState
                  ?.requestState
            )?.displayName ?? '',
          needByDateTime: treatmentPlanRequest.needByDate
            ? new Date(treatmentPlanRequest.needByDate)
            : undefined,
          surgeryDateTime: treatmentPlanRequest.treatment?.surgeryDateTime
            ? new Date(treatmentPlanRequest.treatment?.surgeryDateTime)
            : undefined,
          workflowStatus,
          latestResults: workflowExecution?.results,
        };
      }
    );

    return { tableRecords, isLoading };
  }, [isLoading, patientsByIds, treatmentPlanRequestsByIds]);
}

export default useTableRecords;
