import { Button, Modal } from 'antd';
import React from 'react';

interface Props {
  modalOpen: boolean;
  onClose: any;
}

const ConfirmCaseDoneModal: React.FC<Props> = ({ modalOpen, onClose }) => {
  return (
    <Modal
      title="Finished Case Notice"
      open={modalOpen}
      onCancel={onClose}
      closable={false}
      footer={
        <Button key="back" onClick={onClose} type="primary">
          OK
        </Button>
      }
    >
      <p>
        Attention: By checking this checkbox, I confirm that all the collectable
        data fields for this patient are complete and ready for extraction &
        processing.
      </p>
    </Modal>
  );
};

export default ConfirmCaseDoneModal;
