import { LoadingOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import { useState } from 'react';
import { WorkflowExecutionStatusEnum } from 'solid-workflow-service-typescript-axios';
import useStartWorkflowMutation from '../../hooks/mutations/useStartWorkflowMutation';
import useTreatmentPlanRequestQuery from '../../hooks/queries/useTreatmentPlanRequestQuery';
import findLatestWorkflowExection from '../../utils/findLatestWorkflowExecution';

function GenerateInsightsButton({
  enabled,
  treatmentPlanRequestId,
  onWorkflowStart,
}: {
  treatmentPlanRequestId: string;
  enabled: boolean;
  onWorkflowStart: () => void;
}) {
  const startWorkflowMutation = useStartWorkflowMutation();
  const { isLoading, data } = useTreatmentPlanRequestQuery(
    treatmentPlanRequestId
  );
  const [buttonIsLoading, setButtonIsLoading] = useState(false);

  const startWorkflow = () => {
    onWorkflowStart();
    setButtonIsLoading(true);
    startWorkflowMutation.mutate(treatmentPlanRequestId, {
      onSettled: () => {
        setButtonIsLoading(false);
      },
    });
  };

  const latestWorkflowExecution = findLatestWorkflowExection(
    data?.workflowExecutions
  );

  const confirm =
    latestWorkflowExecution?.status === WorkflowExecutionStatusEnum.Completed ||
    latestWorkflowExecution?.status === WorkflowExecutionStatusEnum.InProgress;

  if (!confirm) {
    return (
      <Button
        type="primary"
        disabled={isLoading || !enabled}
        onClick={startWorkflow}
        loading={isLoading || buttonIsLoading}
        icon={isLoading ? <LoadingOutlined /> : <PlayCircleOutlined />}
      >
        Generate Insights
      </Button>
    );
  }

  return (
    <Popconfirm
      title="Previously generated insights will be overwritten. Do they want to continue?"
      onConfirm={startWorkflow}
      okText="Yes"
      cancelText="No"
    >
      <Button
        type="primary"
        disabled={isLoading || !enabled}
        loading={isLoading || buttonIsLoading}
        icon={isLoading ? <LoadingOutlined /> : <PlayCircleOutlined />}
      >
        Regenerate Insights
      </Button>
    </Popconfirm>
  );
}

export default GenerateInsightsButton;
