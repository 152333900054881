import enGB from 'antd/es/locale/en_GB';
import enUSA from 'antd/es/locale/en_US';
import { Locale } from 'antd/lib/locale-provider';

export type LocaleSettings = {
  locale: Locale;
  language: string;
  dateFormat: string;
  timeFormat: string;
  weightUnit: 'lb' | 'kg';
  heightUnit: 'cm' | 'in';
};

function getLocaleSettings(language: string): LocaleSettings {
  // Currently only using English, and different localization only US - rest default to EU units and formatting
  if (language === 'en-US') {
    return {
      locale: enUSA,
      language: 'en-US',
      dateFormat: 'MM/DD/YYYY ',
      timeFormat: 'h:mm A',
      weightUnit: 'lb',
      heightUnit: 'in',
    };
  } else {
    return {
      locale: enGB,
      language: 'en-GB',
      dateFormat: 'YYYY-MM-DD ',
      timeFormat: 'HH:mm',
      weightUnit: 'kg',
      heightUnit: 'cm',
    };
  }
}

export default getLocaleSettings;
