import { useQuery } from '@tanstack/react-query';
import { TenantsApi } from 'solid-workflow-service-typescript-axios';
import { TENANTS_QUERY_KEY } from '../../utils/consts';
import useSolidWorkflowService from '../useApiConfig';

function useTenantsQuery() {
  const { createApiConfig } = useSolidWorkflowService();

  return useQuery([TENANTS_QUERY_KEY], async () =>
    createApiConfig()
      .then((apiConfig) => new TenantsApi(apiConfig))
      .then((api) => api.tenantsGet(true))
      .then((response) => response.data)
  );
}

export default useTenantsQuery;
