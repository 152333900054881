import { Bar } from '@ant-design/plots';
import { Col, Image, Row, Typography } from 'antd';
import { createScoreConfig } from '../../../../utils/chartConfigs';
import ChartArrow from './ChartArrow';

const { Text, Paragraph } = Typography;
interface Props {
  chartConfig: any;
}

function CScoreDefinitionMoreInfoPage({ chartConfig }: Props) {
  const infoChartConfig = createScoreConfig(chartConfig, 3.1, 16, 100, 1030);
  return (
    <div>
      <Row>
        <Col span={12}>
          <Paragraph style={{ fontSize: 20, fontWeight: 330 }}>
            The <Text strong>Cartilage Loss Probability</Text> is a validated
            assessment of 3D joint space width which
            <Text strong>
              {' '}
              correlates strongly with cartilage thickness.
            </Text>{' '}
            Measurements in each of the four compartments of the knee
            <Text strong>
              {' '}
              are provided, after adjusting for gender differences,
            </Text>{' '}
            and displayed as a single score for each compartment.
          </Paragraph>
        </Col>
        <Col span={12}>
          <Image
            src={process.env.PUBLIC_URL + '/cscore/cscore_diagram.png'}
            alt={'Cartilage Loss Probability Diagram'}
            preview={false}
            width="450px"
            style={{ marginLeft: '20px' }}
          ></Image>
        </Col>
      </Row>
      <Row style={{ marginTop: 10 }}>
        <Bar {...{ ...infoChartConfig, annotations: undefined }} />
        <div style={{ marginLeft: '-0.2rem', marginRight: '0.05rem' }}>┣</div>
        <Text style={{ fontSize: 16 }}>Possible</Text>
        <ChartArrow facing="right" width={25} />
        <ChartArrow facing="left" width={207} />
        <Text style={{ fontSize: 16, marginLeft: 15, marginRight: 15 }}>
          Probable Cartilage Loss
        </Text>
        <ChartArrow facing="right" width={207} />
        <ChartArrow facing="left" width={93} />
        <Text style={{ fontSize: 16, marginLeft: 10, marginRight: 10 }}>
          Severe Cartilage Loss
        </Text>
        <ChartArrow facing="right" width={93} />
        <Text style={{ fontSize: 16, marginLeft: 28, marginTop: -6 }}>
          Loss
        </Text>
      </Row>
    </div>
  );
}
export default CScoreDefinitionMoreInfoPage;
