import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import {
  PreOperativeData,
  TreatmentPlanRequestsApi,
} from 'solid-workflow-service-typescript-axios';
import { TenantContext } from '../../components/TenantContextProvider';
import { TREATMENT_PLAN_REQUEST_QUERY_KEY } from '../../utils/consts';
import useSolidWorkflowService from '../useApiConfig';

interface Variables {
  treatmentPlanRequestId: string;
  preOperativeData: PreOperativeData;
}

function usePreOperativeDataMutation() {
  const { createApiConfig } = useSolidWorkflowService();
  const tenantContext = useContext(TenantContext);
  const tenantId = tenantContext?.tenant?.id;

  const queryClient = useQueryClient();

  const savePreOpData = ({
    preOperativeData,
    treatmentPlanRequestId,
  }: Variables) =>
    createApiConfig()
      .then((apiConfig) => new TreatmentPlanRequestsApi(apiConfig))
      .then(async (api) => {
        await api.treatmentPlanRequestsIdPreOperativeDataPut(
          treatmentPlanRequestId,
          tenantId ?? '',
          preOperativeData
        );
        return treatmentPlanRequestId;
      });

  return useMutation(savePreOpData, {
    onSuccess: (treatmentPlanRequestId) => {
      queryClient.invalidateQueries({
        queryKey: [
          TREATMENT_PLAN_REQUEST_QUERY_KEY,
          tenantId,
          treatmentPlanRequestId,
        ],
      });
    },
  });
}

export default usePreOperativeDataMutation;
