import { useQuery } from '@tanstack/react-query';

import { Patient, PatientsApi } from 'solid-workflow-service-typescript-axios';
import { PATIENTS_QUERY_KEY } from '../../utils/consts';
import useSolidWorkflowService from '../useApiConfig';
import { TenantContext } from '../../components/TenantContextProvider';
import { useContext } from 'react';

function usePatientsQuery(patientIds: string[], enabled: boolean = true) {
  const { createApiConfig } = useSolidWorkflowService();
  const tenantContext = useContext(TenantContext);
  const tenantId = tenantContext?.tenant?.id;

  return useQuery(
    [PATIENTS_QUERY_KEY, tenantId, patientIds],
    () =>
      createApiConfig()
        .then((apiConfig) => new PatientsApi(apiConfig))
        .then((api) =>
          api.patientsSearchesPost(tenantId ?? '', { ids: patientIds })
        )
        .then((response) =>
          response.data.reduce(
            (result, current) => ({
              ...result,
              [current.id]: current,
            }),
            {} as { [id: string]: Patient }
          )
        ),
    { enabled }
  );
}

export default usePatientsQuery;
