import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import getLocaleSettings from '../utils/getLocalSettings';

const useLocaleSettings = () => {
  const { i18n } = useTranslation();

  return useMemo(() => getLocaleSettings(i18n.language), [i18n.language]);
};

export default useLocaleSettings;
