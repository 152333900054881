import { Document } from 'flexsearch';

import { useEffect, useMemo, useState } from 'react';
import { Filters } from '../../hooks/queries/useTreatmentPlanRequestsQuery';
import { TableRecord } from './types';
import useTableRecords from './useTableRecords';

interface Options {
  searchTerm: string;
  filters: Filters;
}

function useIndexedTableRecords({ searchTerm, filters }: Options) {
  const [searchIndex, setSearchIndex] =
    useState<Document<TableRecord, boolean>>();
  const [isIndexing, setIsIndexing] = useState(true);

  const { isLoading, tableRecords } = useTableRecords(filters);

  useEffect(() => {
    if (tableRecords) {
      setIsIndexing(true);
      const searchIndex = new Document<TableRecord, boolean>({
        tokenize: 'full',

        document: {
          id: 'treatmentPlanRequestId',
          index: [
            { field: 'treatmentPlanRequestId', tokenize: 'strict' },
            { field: 'patientId', tokenize: 'strict' },
            { field: 'externalReference' },
            { field: 'patientName' },
            { field: 'primaryContactName' },
            { field: 'surgeonName' },
            { field: 'operativeSide' },
            { field: 'applicationType' },
            { field: 'hospitalName' },
            { field: 'requestState' },
            { field: 'workflowStatus:displayValue' },
          ],
          store: true,
        },
      });

      tableRecords.forEach((treatmentPlanRequest) => {
        searchIndex.add(treatmentPlanRequest);
      });
      setSearchIndex(searchIndex);
      setIsIndexing(false);
    }
  }, [tableRecords]);

  const result = useMemo(() => {
    if (!tableRecords || isIndexing || isLoading) {
      return;
    }

    if (searchIndex && searchTerm) {
      const searchResults = searchIndex.search(searchTerm, { enrich: true });

      const treatmentPlanRequests = new Set<TableRecord>();
      const matchingIndexes: { [treatmentPlanRequestId: string]: string[] } =
        {};

      searchResults.forEach((field) => {
        field.result.forEach((match: any) => {
          const treatmentPlanRequest = match.doc as TableRecord;
          const treatmentPlanRequestId =
            treatmentPlanRequest.treatmentPlanRequestId ?? '';

          if (!(treatmentPlanRequestId in matchingIndexes)) {
            matchingIndexes[treatmentPlanRequestId] = [];
          }
          matchingIndexes[treatmentPlanRequestId].push(field.field);

          return treatmentPlanRequests.add(treatmentPlanRequest);
        });
      });

      return {
        tableRecords: Array.from(treatmentPlanRequests),
        matchingIndexes,
      };
    }

    return {
      tableRecords: tableRecords,
      matchingIndexes: {},
    };
  }, [isIndexing, isLoading, searchIndex, searchTerm, tableRecords]);

  return useMemo(
    () => ({
      isLoading: isLoading || isIndexing,
      tableRecords: result?.tableRecords,
      matchingIndexes: result?.matchingIndexes,
    }),
    [isLoading, isIndexing, result]
  );
}

export default useIndexedTableRecords;
