import { WorkflowResults } from 'solid-workflow-service-typescript-axios';
import React, { CSSProperties, useState } from 'react';
import { Bar } from '@ant-design/plots';
import {
  ScoreColorConfig,
  createScoreConfig,
  getBScoreColor,
} from '../../../../utils/chartConfigs';
import { Card, Typography, Button, Image, Col, Row } from 'antd';
import {
  DoubleLeftOutlined,
  DoubleRightOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import BScoreReferenceExamplesPage from './BScoreReferenceExamplesPage';
import BScoreDefinitionMoreInfoPage from './BScoreDefinitionMoreInfoPage';
import InsightsModal from '../../InsightsModal';

const { Title } = Typography;
interface Props {
  workflowResults: WorkflowResults;
  widgetVisible: boolean;
  style?: CSSProperties;
}

function BScore({
  workflowResults: insightsResults,
  widgetVisible,
  style,
}: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [referenceExamplePageShown, setReferenceExamplePageShown] =
    useState(true);
  if (insightsResults.bScore && widgetVisible) {
    const BConfig: ScoreColorConfig = {
      min: 0,
      max: 8,
      switchToYellow: 2,
      switchToRed: 4,
      reverse: false,
    };

    let bScoreDisplay = insightsResults.bScore?.toFixed(1);
    const bScore = Number(bScoreDisplay);

    let adjustedBScore = bScore;
    if (bScore < 0) {
      bScoreDisplay = '<0.0';
      adjustedBScore = 0.0;
    } else if (bScore > BConfig.max) {
      bScoreDisplay = '8.0+';
      adjustedBScore = 8.0;
    }

    const chartConfig = createScoreConfig(BConfig, bScore);
    const infoChartConfig = createScoreConfig(BConfig, bScore, 18, 120);
    const color = getBScoreColor(BConfig, bScore);

    return (
      <Card
        style={style}
        bordered
        title={<Title level={5}>B-Score</Title>}
        extra={
          <Button
            icon={<InfoCircleOutlined style={{ fontSize: '1.2rem' }} />}
            type="text"
            onClick={() => setIsModalOpen(true)}
          ></Button>
        }
      >
        <Row>
          <Col flex="100px">
            <Title level={3} style={{ color: color, fontWeight: 700 }}>
              {bScoreDisplay}
            </Title>
          </Col>
          <Col flex="auto">
            <Image
              src={
                process.env.PUBLIC_URL +
                '/bscore/bscore-' +
                adjustedBScore.toFixed(1) +
                '.png'
              }
              alt={'B-Score ' + bScoreDisplay}
              preview={false}
              width="175px"
            ></Image>
          </Col>
        </Row>
        <Row style={{ marginTop: '1rem' }}>
          <Col>
            <Bar {...chartConfig} />
          </Col>
        </Row>
        {/* We have two "pages" in one modal by toggling the content */}
        <InsightsModal
          modalOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          title={
            referenceExamplePageShown
              ? "Patient's B-Score with Reference Examples"
              : 'B-Score Definition and More Information'
          }
          switchButtonContent={
            referenceExamplePageShown ? (
              // No Row object wrapper means right aligned button
              <Button
                type="primary"
                size="large"
                onClick={() =>
                  setReferenceExamplePageShown(!referenceExamplePageShown)
                }
                style={{ margin: 10 }}
              >
                Definition & More Info
                <DoubleRightOutlined />
              </Button>
            ) : (
              // Wrapping button in Row object moves it to the left
              <Row>
                <Button
                  type="primary"
                  size="large"
                  onClick={() =>
                    setReferenceExamplePageShown(!referenceExamplePageShown)
                  }
                  style={{ margin: 10 }}
                >
                  <DoubleLeftOutlined />
                  Reference Examples
                </Button>
              </Row>
            )
          }
        >
          {referenceExamplePageShown ? (
            <BScoreReferenceExamplesPage
              chartConfig={infoChartConfig}
              bScore={bScore}
              bScoreAdjusted={adjustedBScore}
              bScoreDisplay={bScoreDisplay}
            />
          ) : (
            <BScoreDefinitionMoreInfoPage />
          )}
        </InsightsModal>
      </Card>
    );
  } else {
    return <p></p>;
  }
}

export default BScore;
