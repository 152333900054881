import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { Tag, Tooltip } from 'antd';
import { WorkflowStatus } from '../TreatmentPlanRequestTable/types';
import { WorkflowResults } from 'solid-workflow-service-typescript-axios';

interface Props {
  workflowStatus: WorkflowStatus;
  large?: boolean;
  latestResults?: WorkflowResults;
  checkTotalCompletion?: boolean;
}

function WorkflowStatusTag({
  workflowStatus,
  large,
  latestResults,
  checkTotalCompletion = false,
}: Props) {
  let icon, color;
  // Checks if all of the models actually passed
  const totalCompletionWithNoErrors =
    latestResults?.fullLegArithmeticHipKneeAnkle &&
    latestResults.surfaces?.boneQualityThresholds?.length === 5 &&
    latestResults.surgicalTimeCemented &&
    latestResults.bScore;

  switch (workflowStatus?.status) {
    case 'inProgress':
      icon = <ClockCircleOutlined spin />;
      color = 'processing';
      break;
    case 'completed':
      icon =
        !checkTotalCompletion || totalCompletionWithNoErrors ? (
          <CheckCircleOutlined />
        ) : (
          <ExclamationCircleOutlined />
        );
      color =
        !checkTotalCompletion || totalCompletionWithNoErrors
          ? 'success'
          : 'warning';
      break;
    case 'error':
      icon = <CloseCircleOutlined />;
      color = 'error';
      break;
    default:
      icon = <ExclamationCircleOutlined />;
      color = 'default';
      break;
  }

  let tag = large ? (
    <Tag
      icon={icon}
      color={color}
      style={{ fontSize: 14, padding: '5px 12px 5px 12px' }}
    >
      {workflowStatus?.displayValue}
    </Tag>
  ) : (
    <Tag icon={icon} color={color}>
      {workflowStatus?.displayValue}
    </Tag>
  );

  if (workflowStatus.error) {
    tag = <Tooltip title={workflowStatus.error.message}>{tag}</Tooltip>;
  }

  return tag;
}

export default WorkflowStatusTag;
