import { useAccessToken } from 'osep-react-antd';
import { useCallback, useMemo } from 'react';
import { Configuration } from 'solid-workflow-service-typescript-axios';
import config from '../config';
import useSilentRequest from './useSilentRequest';

function useSolidWorkflowService() {
  const silentRequest = useSilentRequest();
  const acquireAccessToken = useAccessToken(silentRequest);

  const basePath = `${config.solidBasePath}/api/v1`;

  const createApiConfig = useCallback(
    () =>
      acquireAccessToken()
        .then(({ accessToken }) => `Bearer ${accessToken}`)
        .then(
          (apiKey) =>
            new Configuration({
              basePath,
              apiKey,
            })
        ),
    [acquireAccessToken, basePath]
  );

  return useMemo(() => ({ createApiConfig }), [createApiConfig]);
}

export default useSolidWorkflowService;
