import { Empty, Layout, Menu, PageHeader, Spin, SubMenuProps } from 'antd';
import { notifyUser, useAccessToken } from 'osep-react-antd';
import { ReactNode, useContext, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useNavigate, useParams } from 'react-router-dom';
import ApplicationHeader from '../components/ApplicationHeader';
import { TenantContext } from '../components/TenantContextProvider';
import useTreatmentPlanRequestQuery from '../hooks/queries/useTreatmentPlanRequestQuery';
import useSilentRequest from '../hooks/useSilentRequest';
import findLatestWorkflowExection from '../utils/findLatestWorkflowExecution';
import CenteredPageLayout from './CenteredPageLayout';
import styles from './BaseInsightsPage.module.css';
import InsightsPagesTabs, {
  InsightPagePath,
} from '../components/Insights/InsightsPagesTabs';

interface Props {
  pagePath: InsightPagePath;
  boneCanvas?: ReactNode;
  widgets?: ReactNode;
  boneControls: ReactNode;
  widgetControls?: ReactNode;
  otherControls?: ReactNode;
}
function BaseInsightsPage({
  pagePath,
  boneCanvas,
  widgets,
  boneControls,
  widgetControls,
  otherControls,
}: Props) {
  const { treatmentPlanRequestId } = useParams();
  const { data, isLoading } = useTreatmentPlanRequestQuery(
    treatmentPlanRequestId ?? ''
  );
  const silentRequest = useSilentRequest();
  const acquireAccessToken = useAccessToken(silentRequest);
  const [accessToken, setAccessToken] = useState<string>();

  useEffect(() => {
    acquireAccessToken().then(({ accessToken }) => setAccessToken(accessToken));
  }, [acquireAccessToken]);

  const latestWorkflowExecution = findLatestWorkflowExection(
    data?.workflowExecutions
  );
  const workflowResults = latestWorkflowExecution?.results;

  const tenantContext = useContext(TenantContext);
  const tenantId = tenantContext?.tenant?.id;

  const [collapsed, setCollapsed] = useState(false);
  const [openSubMenus, setOpenSubMenus] = useState(
    widgets ? ['bones', 'widgets', 'osteophytes'] : ['bones']
  );

  const navigate = useNavigate();

  if (isLoading || !tenantId) {
    return (
      <CenteredPageLayout>
        <Spin />
      </CenteredPageLayout>
    );
  }

  if (!workflowResults || !accessToken) {
    return (
      <CenteredPageLayout>
        <Empty />
      </CenteredPageLayout>
    );
  }

  const handleTitleClick: SubMenuProps['onTitleClick'] = (event) => {
    if (openSubMenus.find((sm) => sm === event.key)) {
      setOpenSubMenus(openSubMenus.filter((sm) => sm !== event.key));
    } else {
      setOpenSubMenus([...openSubMenus, event.key]);
    }
  };

  const operativeSideDisplay = data?.treatment?.operativeSide
    ? data?.treatment?.operativeSide.charAt(0).toUpperCase() +
      data?.treatment?.operativeSide.slice(1)
    : '';

  return (
    <div className={`${styles.container} insights-page`}>
      <ApplicationHeader />
      <div>
        <div
          style={{
            position: 'absolute',
            width: '100%',
          }}
        >
          <PageHeader
            onBack={() => navigate('../')}
            title={
              <InsightsPagesTabs
                pagePath={pagePath}
                latestWorkflowExecution={latestWorkflowExecution}
              />
            }
            style={{
              position: 'relative',
              zIndex: 1000,
              marginTop: '-1.6rem',
            }}
          />
          <Layout.Sider
            collapsed={collapsed}
            width={320}
            collapsible
            zeroWidthTriggerStyle={{
              background: 'var(--stryker-primary-color-gold)',
              marginTop: -63,
            }}
            collapsedWidth={0}
            style={{
              zIndex: 1000,
              background: '#fafafa',
              top: -5,
              left: collapsed ? 0 : '24px',
            }}
            theme="light"
            onCollapse={() => setCollapsed(!collapsed)}
          >
            <Menu
              mode="inline"
              style={{
                border: '1px solid rgba(0,0,0,.06)',
                minHeight: 130,
                fontSize: '0.96rem',
              }}
              openKeys={collapsed ? undefined : openSubMenus}
            >
              {workflowResults.surfaces && (
                <>
                  <Menu.Item>
                    Operative Side: <b>{operativeSideDisplay}</b>
                  </Menu.Item>
                  <Menu.SubMenu
                    title="Bones"
                    key="bones"
                    onTitleClick={handleTitleClick}
                  >
                    {boneControls}
                  </Menu.SubMenu>
                  {otherControls}
                  {widgetControls && (
                    <Menu.SubMenu
                      title="Widgets"
                      key="widgets"
                      onTitleClick={handleTitleClick}
                    >
                      {widgetControls}
                    </Menu.SubMenu>
                  )}
                </>
              )}
            </Menu>
          </Layout.Sider>
        </div>
      </div>
      <div className={styles.flexContainer}>
        <div
          style={{
            position: 'absolute',
            top: 0,
            width: '100%',
            height: '100%',
            zIndex: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ErrorBoundary
            fallback={<Empty />}
            onError={(error, info) => {
              console.error(error, info);
              notifyUser(
                'error',
                `Unable to load bone models for ${treatmentPlanRequestId}`
              );
            }}
          >
            {workflowResults?.surfaces ? boneCanvas : <Empty />}
          </ErrorBoundary>
          {/* Right Side Panel Insight Cards */}
          <div
            style={{
              position: 'absolute',
              top: 64,
              right: 0,
              overflow: 'auto',
              height: '100%',
              width: 515,
              maxWidth: '102vw',
              padding: 24,
            }}
            className={'insights-card'}
          >
            {widgets}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BaseInsightsPage;
