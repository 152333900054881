import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import 'osep-react-antd/dist/esm/index.css';
import {
  StrykerComplementaryColor,
  StrykerConfigProvider,
} from 'osep-react-antd';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import InsightStatusWebSocket from './components/Insights/InsightStatusWebSocket';
import MsalAuthenticationError from './components/MsalAuthenticationError';
import TenantContextProvider from './components/TenantContextProvider';
import UserContextProvider from './components/UserContextProvider';
import config from './config';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import LanguageDetector from 'i18next-browser-languagedetector';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import getLocaleSettings from './utils/getLocalSettings';

const queryClient = new QueryClient();

const msal = new PublicClientApplication({
  auth: {
    clientId: config.clientId,
  },
});

i18n.use(LanguageDetector).use(initReactI18next).init({
  // Future implementation of translations stored here
  resources: {},
  fallbackLng: 'enGB',
});

ReactDOM.render(
  <MsalProvider instance={msal}>
    <I18nextProvider i18n={i18n}>
      <StrykerConfigProvider
        applicationColor={StrykerComplementaryColor.TEAL}
        locale={getLocaleSettings(i18n.language).locale}
        direction={i18n.dir()}
      >
        <React.StrictMode>
          <QueryClientProvider client={queryClient}>
            <MsalAuthenticationTemplate
              interactionType={InteractionType.Redirect}
              authenticationRequest={{
                scopes: config.scopes,
                authority: `https://login.microsoftonline.com/${config.tenantId}`,
                redirectUri: window.location.origin,
              }}
              errorComponent={MsalAuthenticationError}
            >
              <UserContextProvider>
                <TenantContextProvider>
                  <BrowserRouter>
                    <App />
                  </BrowserRouter>
                  <ReactQueryDevtools initialIsOpen={false} />
                  <InsightStatusWebSocket />
                </TenantContextProvider>
              </UserContextProvider>
            </MsalAuthenticationTemplate>
          </QueryClientProvider>
        </React.StrictMode>
      </StrykerConfigProvider>
    </I18nextProvider>
  </MsalProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
