import { Button, DatePicker, Form } from 'antd';
import moment from 'moment';
import { Controller, useFormContext } from 'react-hook-form';
import useLocaleSettings from '../../hooks/useLocaleSettings';

type Props = {
  label: string;
  tooltip: string;
  name: string;
  surgeryDateTime?: string;
};

function DateTimePickerInput({ label, tooltip, name, surgeryDateTime }: Props) {
  const locale = useLocaleSettings();
  const { control, setValue } = useFormContext();

  const timeFormat = locale.timeFormat;
  const dateFormat = locale.dateFormat + timeFormat;
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => {
        return (
          <Form.Item label={label} tooltip={tooltip}>
            <DatePicker
              style={{ width: '86%' }}
              onOk={undefined}
              value={value ? moment(value) : undefined}
              format={dateFormat}
              placeholder="Select time"
              showNow={false}
              onSelect={(value) => {
                onChange(value.toISOString());
              }}
              renderExtraFooter={() => (
                <Button.Group>
                  {surgeryDateTime && (
                    <Button
                      onClick={() => {
                        setValue(name, surgeryDateTime, {
                          shouldDirty: true,
                        });
                      }}
                    >
                      Surgery Date
                    </Button>
                  )}
                  <Button
                    onClick={() => {
                      setValue(name, moment().toISOString(), {
                        shouldDirty: true,
                      });
                    }}
                  >
                    Now
                  </Button>
                </Button.Group>
              )}
              showTime={{
                defaultValue: moment('00:00:00', timeFormat),
              }}
            ></DatePicker>
          </Form.Item>
        );
      }}
    />
  );
}

export default DateTimePickerInput;
