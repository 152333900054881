import { Modal, Typography } from 'antd';
import React from 'react';

const { Title } = Typography;
interface Props {
  modalOpen: boolean;
  onClose: any;
  title: string;
  switchButtonContent: any;
}

const InsightsModal: React.FC<Props> = ({
  children,
  modalOpen,
  onClose,
  title,
  switchButtonContent,
}) => {
  return (
    <Modal
      title={<Title level={3}>{title}</Title>}
      open={modalOpen}
      onCancel={onClose}
      footer={switchButtonContent}
      width={'67rem'}
    >
      {children}
    </Modal>
  );
};

export default InsightsModal;
