export interface Config {
  solidBasePath: string;
  clientId: string;
  scopes: string[];
  tenantId: string;
  solidEligibleTprStates: { name: string; displayName: string }[];
  procedureDisplayNames: { name: string; displayName: string }[];
  numberOfTreatmentPlanRequestToLoad: number;
  datetimeFilterAllowedStartDate: string;
}

const windowConfig = (window as any).config;

const config: Config = {
  ...windowConfig,
  scopes: JSON.parse(windowConfig.scopes),
  solidEligibleTprStates: JSON.parse(windowConfig.solidEligibleTprStates),
  procedureDisplayNames: JSON.parse(windowConfig.procedureDisplayNames),
  numberOfTreatmentPlanRequestToLoad:
    windowConfig.numberOfTreatmentPlanRequestToLoad === ''
      ? 1000
      : windowConfig.numberOfTreatmentPlanRequestToLoad,
};

export default config;
