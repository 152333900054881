import { Button, Col, Empty, Menu, Row, Slider, Spin, Switch } from 'antd';
import { useAccessToken } from 'osep-react-antd';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TenantContext } from '../components/TenantContextProvider';
import useTreatmentPlanRequestQuery from '../hooks/queries/useTreatmentPlanRequestQuery';
import useSilentRequest from '../hooks/useSilentRequest';
import findLatestWorkflowExection from '../utils/findLatestWorkflowExecution';
import CenteredPageLayout from './CenteredPageLayout';
import BoneQualityModelCanvas from '../components/Insights/BoneQualityInsights/BoneQualityModelCanvas';
import BaseInsightsPage from './BaseInsightsPage';
import { InfoCircleOutlined } from '@ant-design/icons';
import BoneQualityThresholdInfoModal from '../components/Insights/BoneQualityInsights/BoneQualityThresholdInfoModal';

function BoneQualityInsightsPage() {
  const { treatmentPlanRequestId } = useParams();
  const { data, isLoading } = useTreatmentPlanRequestQuery(
    treatmentPlanRequestId ?? ''
  );
  const silentRequest = useSilentRequest();
  const aquireAccessToken = useAccessToken(silentRequest);
  const [accessToken, setAccessToken] = useState<string>();

  useEffect(() => {
    aquireAccessToken().then(({ accessToken }) => setAccessToken(accessToken));
  }, [aquireAccessToken]);

  const latestWorkflowExecution = findLatestWorkflowExection(
    data?.workflowExecutions
  );
  const workflowResults = latestWorkflowExecution?.results;
  const tenantContext = useContext(TenantContext);
  const tenantId = tenantContext?.tenant?.id;

  // ---------- Settings related to widgets / controls ----------------
  const [displaySettings, setDisplaySettings] = useState({
    showTibia: true,
    showFibula: true,
    thresholdNumber: 200,
  });
  const { showTibia, showFibula, thresholdNumber } = displaySettings;
  const [isModalOpen, setIsModalOpen] = useState(false);

  if (isLoading || !tenantId) {
    return (
      <CenteredPageLayout>
        <Spin />
      </CenteredPageLayout>
    );
  }

  if (!workflowResults?.surfaces?.boneQualityThresholds || !accessToken) {
    return (
      <CenteredPageLayout>
        <Empty />
      </CenteredPageLayout>
    );
  }

  return (
    <BaseInsightsPage
      pagePath="bone-quality-insights"
      boneCanvas={
        workflowResults?.surfaces?.boneQualityThresholds.length === 5 ? (
          <BoneQualityModelCanvas
            tenantId={tenantId}
            accessToken={accessToken}
            surfaces={workflowResults.surfaces}
            boneQualityThresholds={
              workflowResults.surfaces.boneQualityThresholds
            }
            showTibia={showTibia}
            showFibula={showFibula}
            thresholdNumber={thresholdNumber}
            style={{ zIndex: 500 }}
          />
        ) : (
          <Empty />
        )
      }
      boneControls={
        <>
          <Menu.Item>
            <Row>
              <Col span={8} style={{ fontSize: '0.92rem' }}>
                Tibia
              </Col>
              <Col>
                <Switch
                  checked={showTibia}
                  onChange={() =>
                    setDisplaySettings({
                      ...displaySettings,
                      showTibia: !showTibia,
                    })
                  }
                />
              </Col>
            </Row>
          </Menu.Item>
          <Menu.Item>
            <Row>
              <Col span={8} style={{ fontSize: '0.92rem' }}>
                Fibula
              </Col>
              <Col>
                <Switch
                  checked={showFibula}
                  onChange={() =>
                    setDisplaySettings({
                      ...displaySettings,
                      showFibula: !showFibula,
                    })
                  }
                />
              </Col>
            </Row>
          </Menu.Item>
        </>
      }
      otherControls={
        <>
          <Menu.Item style={{ marginTop: '0.5rem' }}>
            <Row>
              <Col span={21} style={{ fontSize: '0.95rem' }}>
                Threshold (HU)
              </Col>
              <Col style={{ marginTop: '-0.3rem' }}>
                <Button
                  type="text"
                  icon={
                    <InfoCircleOutlined
                      style={{ fontSize: '1.1rem' }}
                      onClick={() => setIsModalOpen(true)}
                    />
                  }
                ></Button>
                <BoneQualityThresholdInfoModal
                  modalOpen={isModalOpen}
                  onClose={() => setIsModalOpen(false)}
                />
              </Col>
            </Row>
          </Menu.Item>
          <Menu.Item>
            <Row>
              <Col span={23}>
                <Slider
                  value={thresholdNumber}
                  min={150}
                  max={250}
                  step={25}
                  marks={{ 150: 150, 175: 175, 200: 200, 225: 225, 250: 250 }}
                  onChange={(value) =>
                    setDisplaySettings({
                      ...displaySettings,
                      thresholdNumber: value,
                    })
                  }
                />
              </Col>
            </Row>
          </Menu.Item>
        </>
      }
    />
  );
}

export default BoneQualityInsightsPage;
