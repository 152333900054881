import { useLoader } from '@react-three/fiber';
import { WorkflowResultsSurfaces } from 'solid-workflow-service-typescript-axios';
import { FrontSide } from 'three';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import config from '../../../config';
interface Props {
  accessToken: string;
  surfaces: WorkflowResultsSurfaces;
  showTibia: boolean;
  showFemur: boolean;
  tenantId: string;
}

const boneColor = '#f4efaf';

const baseMeshProps = {
  emissive: 0x000000,
  roughness: 0.5,
  metalness: 0,
  flatShading: false,
  wireframe: false,
  vertexColors: false,
  fog: false,
  depthTest: true,
  depthWrite: true,
  alphaTest: 0,
  visible: true,
  side: FrontSide,
  transparent: true,
};

function FullLegModels({
  surfaces,
  accessToken,
  showTibia,
  showFemur,
  tenantId,
}: Props) {
  const itemIdToUrl = (itemId?: string) => {
    if (!itemId) {
      return `${process.env.PUBLIC_URL}/empty.stl`;
    }

    return `${config.solidBasePath}/api/v1/items/${itemId}/download`;
  };

  const fullFemurId = surfaces.fullLegFemurItemId;
  const fullTibiaId = surfaces.fullLegTibiaItemId;

  const [femurGeom, tibiaGeom] = useLoader(
    STLLoader,
    [fullFemurId, fullTibiaId].map(itemIdToUrl),
    (loader) => {
      loader.setRequestHeader({
        Authorization: `Bearer ${accessToken}`,
        tenant_id: tenantId,
      });
    }
  );

  return (
    <>
      <mesh key={tibiaGeom.uuid} rotation-x={-(Math.PI / 2)}>
        <primitive object={tibiaGeom} attach="geometry" />
        <meshStandardMaterial
          color={boneColor}
          {...baseMeshProps}
          visible={showTibia}
        />
      </mesh>

      <mesh key={femurGeom.uuid} rotation-x={-(Math.PI / 2)}>
        <primitive object={femurGeom} attach="geometry" />
        <meshStandardMaterial
          color={boneColor}
          {...baseMeshProps}
          visible={showFemur}
        />
      </mesh>
    </>
  );
}

export default FullLegModels;
