import { useNavigate } from 'react-router-dom';
import ApplicationHeader from '../components/ApplicationHeader';
import PageContent from '../components/PageContent';
import TreatmentPlanRequestTable from '../components/TreatmentPlanRequestTable';

function TreatmentPlanRequestsPage() {
  const navigate = useNavigate();

  return (
    <>
      <ApplicationHeader />

      <PageContent>
        <TreatmentPlanRequestTable
          onClickTreatmentPlanRequest={(tableRecord) => {
            navigate(
              `treatment-plan-request/${tableRecord.treatmentPlanRequestId}`
            );
          }}
        />
      </PageContent>
    </>
  );
}

export default TreatmentPlanRequestsPage;
