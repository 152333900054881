import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import {
  TreatmentPlanRequestEntity,
  TreatmentPlanRequestsApi,
} from 'solid-workflow-service-typescript-axios';
import { TenantContext } from '../../components/TenantContextProvider';
import config from '../../config';
import { TREATMENT_PLAN_REQUESTS_QUERY_KEY } from '../../utils/consts';
import useApiConfig from '../useApiConfig';
import { Moment } from 'moment';

export type DateTimeFilterType = 'created-in' | 'surgery-starts-in';

export type Filters = {
  dateTimeFilterType: DateTimeFilterType;
  dateTimeStart: Moment;
  dateTimeEnd: Moment;
  requestStates: string[];
};

function useTreatmentPlanRequestsQuery(filters: Filters) {
  const { createApiConfig } = useApiConfig();
  const tenantContext = useContext(TenantContext);
  const tenantId = tenantContext?.tenant?.id;

  const pageSize = config.numberOfTreatmentPlanRequestToLoad;
  const pageIndex = 0;

  const requestStates = filters.requestStates.length
    ? filters.requestStates
    : undefined;

  const dateTimeFrom =
    filters.dateTimeFilterType === 'created-in'
      ? filters.dateTimeStart?.toISOString()
      : undefined;

  const dateTimeTo =
    filters.dateTimeFilterType === 'created-in'
      ? filters.dateTimeEnd?.toISOString()
      : undefined;

  const surgeryDateFrom =
    filters.dateTimeFilterType === 'surgery-starts-in'
      ? filters.dateTimeStart?.toISOString()
      : undefined;

  const surgeryDateTo =
    filters.dateTimeFilterType === 'surgery-starts-in'
      ? filters.dateTimeEnd?.toISOString()
      : undefined;

  return useQuery(
    [
      TREATMENT_PLAN_REQUESTS_QUERY_KEY,
      tenantId,
      pageIndex,
      pageSize,
      requestStates,
      dateTimeFrom,
      dateTimeTo,
      surgeryDateFrom,
      surgeryDateTo,
    ],
    () =>
      createApiConfig()
        .then((apiConfig) => new TreatmentPlanRequestsApi(apiConfig))
        .then((api) =>
          api.treatmentPlanRequestsGet(
            tenantId ?? '',
            requestStates,
            undefined,
            pageIndex,
            pageSize,
            dateTimeFrom,
            dateTimeTo,
            surgeryDateFrom,
            surgeryDateTo
          )
        )
        .then((treatmentPlanRequests) =>
          treatmentPlanRequests.data.content?.reduce(
            (result, current) => ({
              ...result,
              [current.metadata?.requestId ?? '']: current,
            }),
            {} as { [id: string]: TreatmentPlanRequestEntity }
          )
        )
  );
}

export default useTreatmentPlanRequestsQuery;
