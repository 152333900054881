import React from 'react';
interface Props {
  leftPosition: number;
  topPosition?: number;
  label: string;
  fontSize?: number;
}

function CScoreBoneDirectionLabel({
  leftPosition,
  topPosition,
  label,
  fontSize,
}: Props) {
  let left = leftPosition;

  return (
    <p
      style={{
        fontSize: fontSize ?? 22 + 'px',
        color: 'darkgrey',
        fontWeight: 500,
        position: 'absolute',
        top: topPosition ?? 78,
        left,
      }}
    >
      {label}
    </p>
  );
}
export default CScoreBoneDirectionLabel;
