import { useAccessToken } from 'osep-react-antd';
import { createContext, useEffect, useState } from 'react';
import useSilentRequest from '../hooks/useSilentRequest';

interface Value {
  roles: string[];
  firstName?: string;
  lastName?: string;
  email?: string;
  uniqueName?: string;
}

export const UserContext = createContext<Value | undefined>(undefined);

const UserContextProvider: React.FC = ({ children }) => {
  const silentRequest = useSilentRequest();
  const aquireAccessToken = useAccessToken(silentRequest);
  const [value, setValue] = useState<Value>();

  useEffect(() => {
    aquireAccessToken().then(({ payload }) => {
      setValue({
        roles: payload?.roles ?? [],
        email: payload?.email ?? undefined,
        firstName: payload?.given_name ?? undefined,
        lastName: payload?.family_name ?? undefined,
        uniqueName: payload?.unique_name ?? undefined,
      });
    });
  }, [aquireAccessToken]);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export default UserContextProvider;
