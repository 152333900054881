import { InfoCircleOutlined } from '@ant-design/icons';
import { Bar } from '@ant-design/plots';
import { Button, Card, Typography } from 'antd';
import { CSSProperties, useState } from 'react';
import { WorkflowResults } from 'solid-workflow-service-typescript-axios';
import ProcedureTimeMoreInfoModal from './ProcedureTimeMoreInfoModal';
const { Title, Text } = Typography;
interface Props {
  workflowResults: WorkflowResults;
  cementedTimeChartConfig: any;
  cementlessTimeChartConfig: any;
  widgetVisible: boolean;
  style?: CSSProperties;
}

const tabList = [
  {
    key: 'cemented',
    tab: 'Cemented',
  },
  {
    key: 'cementless',
    tab: 'Cementless',
  },
];

const ProcedureTime: React.FC<Props> = ({
  workflowResults,
  cementedTimeChartConfig,
  cementlessTimeChartConfig,
  widgetVisible,
  style,
}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState<'cementless' | 'cemented'>(
    'cemented'
  );

  const cementedTimeChart = cementedTimeChartConfig ? (
    <Bar {...cementedTimeChartConfig} />
  ) : (
    <></>
  );

  const cementlessTimeChart = cementlessTimeChartConfig ? (
    <Bar {...cementlessTimeChartConfig} />
  ) : (
    <></>
  );

  const minutes =
    (activeTabKey === 'cemented'
      ? workflowResults.surgicalTimeCemented?.predictedDurationInMinutes
      : workflowResults.surgicalTimeCementless?.predictedDurationInMinutes
    )?.toFixed(0) ?? '';

  if (widgetVisible) {
    return (
      <Card
        style={style}
        title={<Title level={5}>Predicted Procedure Time</Title>}
        extra={
          <div>
            <Button
              icon={<InfoCircleOutlined style={{ fontSize: '1.2rem' }} />}
              type="text"
              onClick={() => setIsModalOpen(true)}
            ></Button>
          </div>
        }
        tabList={tabList}
        activeTabKey={activeTabKey}
        onTabChange={(key: string) => {
          setActiveTabKey(key as 'cementless' | 'cemented');
        }}
      >
        <ProcedureTimeMoreInfoModal
          modalOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        ></ProcedureTimeMoreInfoModal>
        <Title level={4} style={{ margin: 0 }}>
          {`${minutes}`}
          <Text style={{ fontWeight: '300' }}> minutes</Text>
        </Title>
        {activeTabKey === 'cemented' ? cementedTimeChart : cementlessTimeChart}
      </Card>
    );
  } else {
    return <div />;
  }
};

export default ProcedureTime;
