import { useLoader } from '@react-three/fiber';
import { WorkflowResultsSurfaces } from 'solid-workflow-service-typescript-axios';
import { FrontSide } from 'three';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import config from '../../../config';
import { StrykerComplementaryColor } from 'osep-react-antd';
interface Props {
  accessToken: string;
  surfaces: WorkflowResultsSurfaces;
  osteophytesOpacity: number;
  showTibia: boolean;
  showTibiaOsteophytes: boolean;
  showFemur: boolean;
  showFemurOsteophytes: boolean;
  showMcl: boolean;
  showFibula: boolean;
  flexion: boolean;
  tenantId: string;
}

const boneColor = '#f4efaf';
const osteophytesColor = '#0c7bdc';
const mclColor = StrykerComplementaryColor.PURPLE;

const baseMeshProps = {
  emissive: 0x000000,
  roughness: 0.5,
  metalness: 0,
  flatShading: false,
  wireframe: false,
  vertexColors: false,
  fog: false,
  depthTest: true,
  depthWrite: true,
  alphaTest: 0,
  visible: true,
  side: FrontSide,
  transparent: true,
};

function KneeBoneModels({
  surfaces,
  accessToken,
  osteophytesOpacity,
  showTibia,
  showFemur,
  showFemurOsteophytes,
  showTibiaOsteophytes,
  showMcl,
  showFibula,
  flexion,
  tenantId,
}: Props) {
  const itemIdToUrl = (itemId?: string) => {
    if (!itemId) {
      return `${process.env.PUBLIC_URL}/empty.stl`;
    }

    return `${config.solidBasePath}/api/v1/items/${itemId}/download`;
  };

  const [
    femurGeom,
    tibiaGeom,
    femurOsteophiteGeom,
    tibiaOsteophiteGeom,
    femurMclGeom,
    tibiaMclGeom,
    fibulaGeom,
  ] = useLoader(
    STLLoader,
    [
      flexion
        ? surfaces.femurBoneOnlyFlexion90DegItemId
        : surfaces.femurBoneOnlyItemId,
      flexion
        ? surfaces.tibiaBoneOnlyFlexion90DegItemId
        : surfaces.tibiaBoneOnlyItemId,
      flexion
        ? surfaces.femurOsteophytesFullFlexion90DegItemId
        : surfaces.femurOsteophytesFullItemId,
      flexion
        ? surfaces.tibiaOsteophytesFullFlexion90DegItemId
        : surfaces.tibiaOsteophytesFullItemId,
      flexion ? surfaces.femurMclFlexion90DegItemId : surfaces.femurMclItemId,
      flexion ? surfaces.tibiaMclFlexion90DegItemId : surfaces.tibiaMclItemId,
      flexion ? surfaces.fibulaFlexion90DegItemId : surfaces.fibulaItemId,
    ].map(itemIdToUrl),
    (loader) => {
      loader.setRequestHeader({
        Authorization: `Bearer ${accessToken}`,
        tenant_id: tenantId,
      });
    }
  );

  return (
    <>
      <mesh key={tibiaGeom.uuid} rotation-x={-(Math.PI / 2)}>
        <primitive object={tibiaGeom} attach="geometry" />
        <meshStandardMaterial
          color={boneColor}
          {...baseMeshProps}
          visible={showTibia}
        />
      </mesh>

      <mesh key={femurGeom.uuid} rotation-x={-(Math.PI / 2)}>
        <primitive object={femurGeom} attach="geometry" />
        <meshStandardMaterial
          color={boneColor}
          {...baseMeshProps}
          visible={showFemur}
        />
      </mesh>

      <mesh key={femurOsteophiteGeom.uuid} rotation-x={-(Math.PI / 2)}>
        <primitive object={femurOsteophiteGeom} attach="geometry" />
        <meshStandardMaterial
          color={osteophytesColor}
          {...baseMeshProps}
          opacity={osteophytesOpacity}
          visible={showFemurOsteophytes}
        />
      </mesh>

      <mesh key={tibiaOsteophiteGeom.uuid} rotation-x={-(Math.PI / 2)}>
        <primitive object={tibiaOsteophiteGeom} attach="geometry" />
        <meshStandardMaterial
          color={osteophytesColor}
          {...baseMeshProps}
          opacity={osteophytesOpacity}
          visible={showTibiaOsteophytes}
        />
      </mesh>

      <mesh key={femurMclGeom.uuid} rotation-x={-(Math.PI / 2)}>
        <primitive object={femurMclGeom} attach="geometry" />
        <meshStandardMaterial
          color={mclColor}
          {...baseMeshProps}
          visible={showMcl}
        />
      </mesh>

      <mesh key={tibiaMclGeom.uuid} rotation-x={-(Math.PI / 2)}>
        <primitive object={tibiaMclGeom} attach="geometry" />
        <meshStandardMaterial
          color={mclColor}
          {...baseMeshProps}
          visible={showMcl}
        />
      </mesh>

      <mesh key={fibulaGeom.uuid} rotation-x={-(Math.PI / 2)}>
        <primitive object={fibulaGeom} attach="geometry" />
        <meshStandardMaterial
          color={boneColor}
          {...baseMeshProps}
          visible={showFibula}
        />
      </mesh>
    </>
  );
}

export default KneeBoneModels;
