import { CheckOutlined } from '@ant-design/icons';
import WorkflowStatusTag from '../TreatmentPlanRequestDetails/WorkflowStatusTag';
import { useContext, useMemo } from 'react';
import { TenantContext } from '../TenantContextProvider';
import { TableRecord, WorkflowStatus } from './types';
import useLocaleSettings from '../../hooks/useLocaleSettings';

function useColumns({
  matchingIndexes,
}: {
  matchingIndexes: { [treatmentPlanRequestId: string]: string[] };
}) {
  const tenantContext = useContext(TenantContext);
  const tenant = tenantContext?.tenant;
  const locale = useLocaleSettings();

  return useMemo(() => {
    function renderWithSearchResult(
      text: string,
      tableRecord: TableRecord,
      searchIndex: string
    ) {
      if (
        matchingIndexes[tableRecord.treatmentPlanRequestId]?.indexOf(
          searchIndex
        ) > -1
      ) {
        return (
          <span
            style={{
              paddingTop: 5,
              paddingBottom: 5,
              backgroundColor: '#ffe58f',
            }}
          >
            {text}
          </span>
        );
      }
      return text;
    }

    let tableRecords: any = [
      {
        title: 'TPR ID',
        dataIndex: ['externalReference'],
        key: 'tprId',
        fixed: 'left',
        render: (externalReference: string, record: TableRecord) =>
          renderWithSearchResult(
            externalReference,
            record,
            'externalReference'
          ),
        sorter: (a: TableRecord, b: TableRecord) =>
          a.externalReference.localeCompare(b.externalReference),
      },
    ];

    if (tenant && tenant.usePatientConsent) {
      tableRecords.push({
        title: 'Consent',
        dataIndex: ['patientConsentGranted'],
        key: 'consent',
        render: (patientConsentGranted: boolean) =>
          patientConsentGranted ? (
            <CheckOutlined style={{ marginLeft: 22 }} />
          ) : (
            <div />
          ),
        sorter: (a: TableRecord, b: TableRecord) =>
          Number(a.patientConsentGranted) - Number(b.patientConsentGranted),
      });
    }

    tableRecords = [
      ...tableRecords,
      {
        title: 'Hospital',
        dataIndex: ['hospitalName'],
        key: 'hospitalName',
        render: (hospitalName: string, record: TableRecord) =>
          renderWithSearchResult(hospitalName, record, 'hospitalName'),
        sorter: (a: TableRecord, b: TableRecord) =>
          a.hospitalName.localeCompare(b.hospitalName),
      },
      {
        title: 'Surgeon',
        dataIndex: ['surgeonName'],
        key: 'surgeonName',
        render: (surgeonName: string, record: TableRecord) =>
          renderWithSearchResult(surgeonName, record, 'surgeonName'),
        sorter: (a: TableRecord, b: TableRecord) =>
          a.surgeonName.localeCompare(b.surgeonName),
      },
      {
        title: 'Operative Side',
        dataIndex: ['operativeSide'],
        key: 'operativeSide',
        render: (operativeSide: string, record: TableRecord) =>
          renderWithSearchResult(operativeSide, record, 'operativeSide'),
        sorter: (a: TableRecord, b: TableRecord) =>
          a.operativeSide.localeCompare(b.operativeSide),
      },
      {
        title: 'Procedure',
        dataIndex: ['applicationType'],
        key: 'applicationType',
        render: (applicationType: string, record: TableRecord) =>
          renderWithSearchResult(applicationType, record, 'applicationType'),
        sorter: (a: TableRecord, b: TableRecord) =>
          a.applicationType.localeCompare(b.applicationType),
      },
      {
        title: 'Patient',
        dataIndex: ['patientName'],
        key: 'patientName',
        render: (patientName: string, record: TableRecord) =>
          renderWithSearchResult(patientName, record, 'patientName'),
        sorter: (a: TableRecord, b: TableRecord) =>
          a.patientName.localeCompare(b.patientName),
      },
      {
        title: 'Request State',
        dataIndex: ['requestState'],
        key: 'requestState',
        render: (requestState: string, record: TableRecord) =>
          renderWithSearchResult(requestState, record, 'requestState'),
        sorter: (a: TableRecord, b: TableRecord) =>
          a.requestState.localeCompare(b.requestState),
      },
      {
        title: 'Primary MPS',
        dataIndex: ['primaryContactName'],
        key: 'primaryContactName',
        render: (primaryContactName: string, record: TableRecord) =>
          renderWithSearchResult(
            primaryContactName,
            record,
            'primaryContactName'
          ),
        sorter: (a: TableRecord, b: TableRecord) =>
          a.primaryContactName.localeCompare(b.primaryContactName),
      },
      {
        title: 'Need by Date',
        dataIndex: ['needByDateTime'],
        key: 'needByDateTime',
        render: (needByDateTime?: Date) =>
          needByDateTime
            ? needByDateTime.toLocaleString(locale.language, {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              })
            : undefined,
        sorter: (a: TableRecord, b: TableRecord) =>
          (a.needByDateTime?.getTime() ?? 0) -
          (b.needByDateTime?.getTime() ?? 0),
      },
      {
        title: 'Surgery Start Date',
        dataIndex: ['surgeryDateTime'],
        key: 'surgeryDateTime',
        render: (surgeryDateTime?: Date) => {
          return surgeryDateTime
            ? surgeryDateTime.toLocaleString(locale.language, {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              })
            : undefined;
        },
        sorter: (a: TableRecord, b: TableRecord) =>
          (a.surgeryDateTime?.getTime() ?? 0) -
          (b.surgeryDateTime?.getTime() ?? 0),
      },
      {
        title: 'Insights Status',
        dataIndex: ['workflowStatus'],
        key: 'workflowStatus',
        filters: [
          { text: 'Completed', value: 'Completed' },
          { text: 'In Progress', value: 'In Progress' },
          { text: 'Error', value: 'Error' },
          { text: 'Not Started', value: 'Not Started' },
        ],
        onFilter: (value: string | number | boolean, record: TableRecord) => {
          const displayValue: WorkflowStatus['displayValue'] = value as any;

          return record.workflowStatus?.displayValue === displayValue;
        },
        render: (workflowStatus: WorkflowStatus, record: TableRecord) => (
          <WorkflowStatusTag
            workflowStatus={workflowStatus}
            latestResults={record.latestResults}
            checkTotalCompletion
          />
        ),
        sorter: (a: TableRecord, b: TableRecord) =>
          (a.workflowStatus?.displayValue ?? '').localeCompare(
            b.workflowStatus?.displayValue ?? ''
          ),
      },
      {
        title: 'Predicted Procedure Time',
        children: [
          {
            title: 'Cementless',
            dataIndex: ['cementlessPredictedSurgicalTime'],
            key: 'cementlessPredictedSurgicalTime',
            render: (
              cementlessPredictedSurgicalTime: TableRecord['cementlessPredictedSurgicalTime']
            ) => (
              <span>
                {cementlessPredictedSurgicalTime ? (
                  <span>{cementlessPredictedSurgicalTime}</span>
                ) : (
                  ''
                )}
              </span>
            ),
            sortDirections: ['descend', 'ascend'],
            sorter: (a: TableRecord, b: TableRecord) =>
              (a.cementlessPredictedSurgicalTime ?? '').localeCompare(
                b.cementlessPredictedSurgicalTime ?? ''
              ),
          },
          {
            title: 'Cemented',
            dataIndex: ['cementedPredictedSurgicalTime'],
            key: 'cementedPredictedSurgicalTime',
            render: (
              cementedPredictedSurgicalTime: TableRecord['cementedPredictedSurgicalTime']
            ) => (
              <span>
                {cementedPredictedSurgicalTime ? (
                  <span>{cementedPredictedSurgicalTime}</span>
                ) : (
                  ''
                )}
              </span>
            ),
            sortDirections: ['descend', 'ascend'],
            sorter: (a: TableRecord, b: TableRecord) =>
              (a.cementedPredictedSurgicalTime ?? '').localeCompare(
                b.cementedPredictedSurgicalTime ?? ''
              ),
          },
        ],
      },
    ];

    return tableRecords;
  }, [matchingIndexes, tenant]);
}

export default useColumns;
