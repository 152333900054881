import { WorkflowExecution } from 'solid-workflow-service-typescript-axios';

function findLatestWorkflowExection(workflowExecutions?: WorkflowExecution[]) {
  if (!workflowExecutions || workflowExecutions.length <= 0) {
    return undefined;
  }

  const sortedWorkflowExecutions = workflowExecutions.sort(
    (one: WorkflowExecution, two: WorkflowExecution) =>
      Date.parse(two.createdAt) - Date.parse(one.createdAt)
  );

  return sortedWorkflowExecutions[0];
}

export default findLatestWorkflowExection;
