import { LogoutOutlined, SwitcherOutlined } from '@ant-design/icons';
import { useMsal } from '@azure/msal-react';
import { StrykerApplicationHeader, StrykerUserMenu } from 'osep-react-antd';
import { StrykerUserMenuItem } from 'osep-react-antd/dist/cjs/components/StrykerUserMenu';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { VERSION } from '../utils/version';
import { TenantContext } from './TenantContextProvider';
import { UserContext } from './UserContextProvider';

function ApplicationHeader() {
  const { instance } = useMsal();
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const tenantContext = useContext(TenantContext);

  if (!userContext || !tenantContext) {
    return <></>;
  }

  const { email, firstName, lastName, uniqueName } = userContext;
  const { availableTenants, tenant, setTenant } = tenantContext;

  const userName =
    firstName && lastName
      ? `${firstName} ${lastName}`
      : email ?? uniqueName ?? 'User';

  const tenantItems: StrykerUserMenuItem[] | undefined =
    availableTenants.length > 1
      ? [
          {
            label: 'Switch Tenant',
            children: availableTenants
              .filter((t) => t.id !== tenant?.id)
              .map((t) => ({
                label: t.name,
                key: t.id,
                icon: <SwitcherOutlined />,
              })),
            type: 'group',
          },
        ]
      : undefined;

  const commonItems: StrykerUserMenuItem[] = [
    {
      label: 'User',
      children: [{ label: 'Logout', key: 'logout', icon: <LogoutOutlined /> }],
      type: 'group',
    },
    {
      label: 'Version',
      children: [
        {
          label: VERSION,
          key: 'version',
          clickable: false,
        },
      ],
      type: 'group',
    },
  ];

  const items: StrykerUserMenuItem[] = tenantItems
    ? [...tenantItems, ...commonItems]
    : commonItems;

  return (
    <StrykerApplicationHeader
      applicationName="SOLID Insights"
      onLogoClick={() => navigate(`/${tenant?.id}`)}
      extra={
        <StrykerUserMenu
          onClick={({ key }) => {
            if (key === 'logout') {
              instance.logoutRedirect();
              return;
            }

            if (availableTenants.find((t) => t.id === key)) {
              setTenant(key);
              navigate(`/${key}`);
              return;
            }
          }}
          userName={userName}
          items={items}
        />
      }
    />
  );
}

export default ApplicationHeader;
