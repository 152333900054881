import { Center, OrbitControls, PerspectiveCamera } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';

import { CSSProperties, Suspense, useRef } from 'react';
import { WorkflowResultsSurfaces } from 'solid-workflow-service-typescript-axios';
import BoneModelLoadingIndicator from '../BoneModelLoadingIndicator';
import FullLegModels from './FullLegModels';

interface Props {
  surfaces: WorkflowResultsSurfaces;
  accessToken: string;
  showTibia: boolean;
  showFemur: boolean;
  tenantId: string;
  style?: CSSProperties;
}

function FullLegModelCanvas({
  tenantId,
  surfaces,
  accessToken,
  showTibia,
  showFemur,
  style,
}: Props) {
  const orbitRef = useRef<any>(null);
  const handleDoubleClick = () => {
    if (orbitRef.current) {
      orbitRef.current.reset();
    }
  };

  return (
    <Canvas onDoubleClick={handleDoubleClick} style={style}>
      <PerspectiveCamera
        makeDefault
        position={[0, 0, 1050]}
        near={1}
        far={2000}
      >
        <directionalLight
          position={[1, 1, 2]}
          color="#ffffff"
          intensity={0.75}
        />
        <pointLight position={[0, -25, 10]} color="#ffffff" intensity={0.3} />
      </PerspectiveCamera>
      <ambientLight color={'#ffffff'} intensity={0.1} />
      <Suspense fallback={<BoneModelLoadingIndicator />}>
        <Center>
          <OrbitControls maxDistance={1600} minDistance={0} ref={orbitRef} />
          <FullLegModels
            tenantId={tenantId}
            surfaces={surfaces}
            accessToken={accessToken}
            showFemur={showFemur}
            showTibia={showTibia}
          />
        </Center>
      </Suspense>
    </Canvas>
  );
}

export default FullLegModelCanvas;
