import { WorkflowResults } from 'solid-workflow-service-typescript-axios';
import { CSSProperties, useEffect, useState } from 'react';
import { Bar } from '@ant-design/plots';
import {
  ScoreColorConfig,
  createScoreConfig,
  getBScoreColor,
} from '../../../../utils/chartConfigs';
import { Typography, Col, Row } from 'antd';
const { Text } = Typography;
interface Props {
  workflowResults: WorkflowResults;
  style?: CSSProperties;
}

function getCurrentDimension() {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  };
}

function BScoreSmallPreview({
  workflowResults: insightsResults,
  style,
}: Props) {
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener('resize', updateDimension);
    return () => {
      window.removeEventListener('resize', updateDimension);
    };
  }, [screenSize]);
  if (insightsResults.bScore) {
    const BConfig: ScoreColorConfig = {
      min: 0,
      max: 8,
      switchToYellow: 2,
      switchToRed: 4,
      reverse: false,
    };

    let bScoreDisplay = insightsResults.bScore?.toFixed(1);
    const bScore = Number(bScoreDisplay);

    if (bScore < 0) {
      bScoreDisplay = '<0.0';
    } else if (bScore > BConfig.max) {
      bScoreDisplay = '8.0+';
    }

    const chartConfig = createScoreConfig(
      BConfig,
      bScore,
      12,
      50,
      screenSize.width / 6
    );
    const color = getBScoreColor(BConfig, bScore);

    return (
      <div
        style={{
          border: '1px solid lightgrey',
          margin: '0.5rem -0.5rem 0 0',
          padding: '0.5rem',
        }}
      >
        <Row>
          <Col flex="100px">
            <Text style={{ fontWeight: 500 }}>B-Score:</Text>
            <Text style={{ color: color, fontWeight: 700 }}>
              {' '}
              {bScoreDisplay}
            </Text>
          </Col>
        </Row>
        <Row style={{ marginTop: '1rem' }}>
          <Col span={23}>
            <Bar {...chartConfig} />
          </Col>
        </Row>
      </div>
    );
  } else {
    return <p></p>;
  }
}

export default BScoreSmallPreview;
