import { Checkbox, Col, Divider, Form, Radio, Row, Select } from 'antd';
import { notifyUser } from 'osep-react-antd';
import { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  PostOperativeData,
  PostOperativeDataFixationTypeEnum,
  PostOperativeDataSpecialCircumstanceEnum,
  TreatmentPlanRequestEntity,
} from 'solid-workflow-service-typescript-axios';

import usePostOperativeDateMutation from '../../hooks/mutations/usePostOperativeDateMutation';
import AutoSave from '../AutoSave';
import ConfirmCaseDoneModal from './ConfirmCaseDoneModal';
import DateTimePickerInput from './DateTimePickerInput';
import { FormState } from './types';
import useStartDataExtractionMutation from '../../hooks/mutations/useStartDataExtractionMutation';

interface Props {
  treatmentPlanRequest?: TreatmentPlanRequestEntity;
  onStateChange: (state: FormState) => void;
}

type FormValues = {
  actualIncisionDateTime?: string;
  actualClosureDateTime?: string;
  specialCircumstance: PostOperativeDataSpecialCircumstanceEnum[];
  fixationType?: PostOperativeDataFixationTypeEnum;
  readyForExtraction: boolean;
};

function PostOperativeForm({ treatmentPlanRequest, onStateChange }: Props) {
  const postOperativeData = treatmentPlanRequest?.postOperativeData;

  const actualIncisionDateTime = postOperativeData?.actualIncisionDateTime;
  const actualClosureDateTime = postOperativeData?.actualClosureDateTime;

  const defaultValues: FormValues = {
    actualIncisionDateTime: actualIncisionDateTime,
    actualClosureDateTime: actualClosureDateTime,
    specialCircumstance: postOperativeData?.specialCircumstance ?? [],
    fixationType: postOperativeData?.fixationType,
    readyForExtraction: postOperativeData?.readyForExtraction ?? false,
  };

  const form = useForm({ defaultValues });

  const savePostOperativeData = usePostOperativeDateMutation();
  const startDataExtraction = useStartDataExtractionMutation();

  const {
    control,
    reset,
    formState: { isDirty, isSubmitting, isSubmitSuccessful },
  } = form;

  const [submittedData, setSubmittedData] = useState<FormValues>();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);

  const saveForm = (data: FormValues): Promise<void> => {
    const extractData =
      form.getFieldState('readyForExtraction').isTouched &&
      data.readyForExtraction === true;

    const treatmentPlanRequestId = treatmentPlanRequest?.metadata?.requestId;

    if (!treatmentPlanRequestId) {
      return Promise.resolve();
    }

    const postOperativeData: PostOperativeData = { ...data };

    return savePostOperativeData
      .mutateAsync({
        treatmentPlanRequestId,
        postOperativeData,
      })
      .then(() => {
        setSubmittedData(data);
      })
      .catch(() => {
        reset(defaultValues);
        notifyUser(
          'error',
          `Could not save post-operative data for ${treatmentPlanRequest?.externalReference}`
        );
      })
      .then(() => {
        if (extractData) {
          return startDataExtraction.mutateAsync();
        }
      });
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({ ...submittedData }, { keepValues: true });
    }
  }, [isSubmitSuccessful, submittedData, reset]);

  const isSaving = isDirty || isSubmitting;

  useEffect(() => {
    onStateChange(isSaving ? 'saving' : 'saved');
  }, [isSaving, onStateChange]);

  return (
    <>
      <FormProvider {...form}>
        <Form layout="vertical">
          <Row gutter={[16, 8]}>
            <Col xs={24} sm={12}>
              <DateTimePickerInput
                name="actualIncisionDateTime"
                label="Incision"
                tooltip="Time when first skin incision was performed to start knee exposure."
                surgeryDateTime={
                  treatmentPlanRequest?.treatment?.surgeryDateTime ?? ''
                }
              />
            </Col>
            <Col xs={24} sm={12}>
              <DateTimePickerInput
                name="actualClosureDateTime"
                label="Closure"
                tooltip="Time when skin closure was finished."
                surgeryDateTime={
                  treatmentPlanRequest?.treatment?.surgeryDateTime ?? ''
                }
              />
            </Col>
            <Col xs={24}>
              <Controller
                name="fixationType"
                control={control}
                render={({ field }) => (
                  <Form.Item label="Fixation Type">
                    <Radio.Group {...field}>
                      <Radio value="cemented">Cemented</Radio>
                      <Radio value="cementless">Cementless</Radio>
                    </Radio.Group>
                  </Form.Item>
                )}
              />
            </Col>
            <Col xs={24}>
              <Controller
                name="specialCircumstance"
                control={control}
                render={({ field }) => (
                  <Form.Item label="Special Circumstance">
                    <Select mode="multiple" allowClear={true} {...field}>
                      <Select.Option
                        value={
                          PostOperativeDataSpecialCircumstanceEnum.Emergency
                        }
                      >
                        Emergency
                      </Select.Option>
                      <Select.Option
                        value={
                          PostOperativeDataSpecialCircumstanceEnum.VisitorPresent
                        }
                      >
                        Visitor Present
                      </Select.Option>
                      <Select.Option
                        value={
                          PostOperativeDataSpecialCircumstanceEnum.LogisticOrEquipmentIssue
                        }
                      >
                        Logistic or Equipment Issue
                      </Select.Option>
                      <Select.Option
                        value={
                          PostOperativeDataSpecialCircumstanceEnum.BilateralCase
                        }
                      >
                        Bilateral Case
                      </Select.Option>
                      <Select.Option
                        value={PostOperativeDataSpecialCircumstanceEnum.Other}
                      >
                        Other
                      </Select.Option>
                    </Select>
                  </Form.Item>
                )}
              />
            </Col>
          </Row>
          <Row>
            <Divider />
          </Row>
          <Row>
            <Col xs={24}>
              <Controller
                name="readyForExtraction"
                control={control}
                render={({ field }) => (
                  <Form.Item>
                    <Checkbox
                      {...field}
                      checked={field.value}
                      onClick={() => setIsConfirmModalOpen(!field.value)}
                    />
                    <label style={{ marginLeft: 8 }}>
                      The case is done and ready for extraction.
                    </label>
                  </Form.Item>
                )}
              />
            </Col>
          </Row>
          <AutoSave onSubmit={saveForm} defaultValues={defaultValues} />
          <ConfirmCaseDoneModal
            modalOpen={isConfirmModalOpen}
            onClose={() => setIsConfirmModalOpen(false)}
          ></ConfirmCaseDoneModal>
        </Form>
      </FormProvider>
    </>
  );
}

export default PostOperativeForm;
