import { Typography, Image, Col, Row } from 'antd';
import React, { CSSProperties } from 'react';
import {
  createScoreConfig,
  getCScoreColor,
  ScoreColorConfig,
} from '../../../../utils/chartConfigs';
import { BoneProps } from './CScore';
import { Bar } from '@ant-design/plots';
import CScoreBoneImageNumber from './CScoreBoneImageNumber';
import CScoreBoneDirectionLabel from './CScoreBoneDirectionLabel';

const { Title, Text } = Typography;
interface Props {
  patello: BoneProps;
  tibio: BoneProps;
  operativeSide: string;
  chartConfig: ScoreColorConfig;
}
const labelStyle: CSSProperties = {
  fontSize: 18,
  fontWeight: 400,
  marginBottom: 5,
};

const BONE_NUMBER_TOP_POSITION = 60;
const BONE_NUMBER_FONT_SIZE = 30;

function CScoreInContextPage({
  patello,
  tibio,
  operativeSide,
  chartConfig,
}: Props) {
  const opSideLeft = operativeSide === 'Left';
  const leftLabel = opSideLeft ? 'Medial' : 'Lateral';
  const rightLabel = opSideLeft ? 'Lateral' : 'Medial';
  const patelloMedialColor = getCScoreColor(
    chartConfig,
    opSideLeft ? patello.medialValue : patello.lateralValue
  );
  const patelloLateralColor = getCScoreColor(
    chartConfig,
    opSideLeft ? patello.lateralValue : patello.medialValue
  );
  const tibioMedialColor = getCScoreColor(
    chartConfig,
    opSideLeft ? tibio.medialValue : tibio.lateralValue
  );
  const tibioLateralColor = getCScoreColor(
    chartConfig,
    opSideLeft ? tibio.lateralValue : tibio.medialValue
  );
  return (
    <div>
      {/* ----------- Patellofemoral Compartment ----------*/}
      <Row>
        <Col span={8}>
          <Row>
            <Col flex={'auto'} style={{ textAlign: 'center' }}>
              <p style={labelStyle}>{leftLabel} Patellofemoral Compartment</p>
            </Col>
          </Row>
          <Row>
            <Col flex={'auto'} style={{ textAlign: 'center' }}>
              <Bar
                {...createScoreConfig(
                  chartConfig,
                  opSideLeft ? patello.medialValue : patello.lateralValue,
                  12,
                  65,
                  330
                )}
              />{' '}
            </Col>
          </Row>
          <Row>
            <Col flex={'auto'} style={{ textAlign: 'center', marginTop: 5 }}>
              <Title level={4}>
                <Text style={{ color: patelloMedialColor }}>
                  {opSideLeft
                    ? patello.medialLossText
                    : patello.lateralLossText}
                </Text>{' '}
                Cartilage Loss
              </Title>
            </Col>
          </Row>
        </Col>
        <Col span={1} />
        {/* Patellofemoral Image and Labels */}
        <Col span={6}>
          <Image
            src={
              process.env.PUBLIC_URL +
              '/cscore/patellofemoral/' +
              operativeSide.toLowerCase() +
              '_operative_side/' +
              patello.medialLossText +
              '_' +
              patello.lateralLossText +
              '.png'
            }
            alt={'Patellofemoral Image'}
            preview={false}
            width="225px"
            style={{ marginLeft: '5px' }}
          ></Image>
          <CScoreBoneImageNumber
            leftPosition={46}
            topPosition={BONE_NUMBER_TOP_POSITION}
            value={opSideLeft ? patello.medialValue : patello.lateralValue}
            fontSize={BONE_NUMBER_FONT_SIZE}
            chartConfig={chartConfig}
          />
          <CScoreBoneImageNumber
            leftPosition={135}
            topPosition={BONE_NUMBER_TOP_POSITION}
            value={opSideLeft ? patello.lateralValue : patello.medialValue}
            fontSize={BONE_NUMBER_FONT_SIZE}
            chartConfig={chartConfig}
          />
          <CScoreBoneDirectionLabel
            leftPosition={116}
            topPosition={-25}
            label={'S'}
          />
          <CScoreBoneDirectionLabel
            leftPosition={234}
            topPosition={60}
            label={rightLabel.slice(0, 1)}
          />
          <CScoreBoneDirectionLabel
            leftPosition={118}
            topPosition={170}
            label={'I'}
          />
          <CScoreBoneDirectionLabel
            leftPosition={opSideLeft ? -16 : -12}
            topPosition={60}
            label={leftLabel.slice(0, 1)}
          />
        </Col>
        <Col span={1} />
        <Col span={8}>
          <Row>
            <Col flex={'auto'} style={{ textAlign: 'center' }}>
              <p style={labelStyle}>{rightLabel} Patellofemoral Compartment</p>
            </Col>
          </Row>
          <Row>
            <Col flex={'auto'} style={{ textAlign: 'center' }}>
              <Bar
                {...createScoreConfig(
                  chartConfig,
                  opSideLeft ? patello.lateralValue : patello.medialValue,
                  12,
                  65,
                  330
                )}
              />{' '}
            </Col>
          </Row>
          <Row>
            <Col flex={'auto'} style={{ textAlign: 'center', marginTop: 5 }}>
              <Title level={4}>
                <Text style={{ color: patelloLateralColor }}>
                  {opSideLeft
                    ? patello.lateralLossText
                    : patello.medialLossText}
                </Text>{' '}
                Cartilage Loss
              </Title>
            </Col>
          </Row>
        </Col>
      </Row>
      {/* ----------- Tibiofemoral Compartment ----------*/}
      <Row style={{ marginTop: 80 }}>
        <Col span={8}>
          <Row>
            <Col flex={'auto'} style={{ textAlign: 'center' }}>
              <p style={labelStyle}>{leftLabel} Tibiofemoral Compartment</p>
            </Col>
          </Row>
          <Row>
            <Col flex={'auto'} style={{ textAlign: 'center' }}>
              <Bar
                {...createScoreConfig(
                  chartConfig,
                  opSideLeft ? tibio.medialValue : tibio.lateralValue,
                  12,
                  65,
                  330
                )}
              />
            </Col>
          </Row>
          <Row>
            <Col flex={'auto'} style={{ textAlign: 'center', marginTop: 5 }}>
              <Title level={4}>
                <Text style={{ color: tibioMedialColor }}>
                  {opSideLeft ? tibio.medialLossText : tibio.lateralLossText}
                </Text>{' '}
                Cartilage Loss
              </Title>
            </Col>
          </Row>
        </Col>
        <Col span={1} />
        {/* Tibiofemoral Image and Labels */}
        <Col span={6}>
          <Image
            src={
              process.env.PUBLIC_URL +
              '/cscore/tibiofemoral/' +
              operativeSide.toLowerCase() +
              '_operative_side/' +
              tibio.medialLossText +
              '_' +
              tibio.lateralLossText +
              '.png'
            }
            alt={'Tibiofemoral Image'}
            preview={false}
            width="260px"
            style={{ marginLeft: '-12px' }}
          ></Image>
          <CScoreBoneImageNumber
            leftPosition={opSideLeft ? 22 : 12}
            topPosition={BONE_NUMBER_TOP_POSITION}
            value={opSideLeft ? tibio.medialValue : tibio.lateralValue}
            fontSize={BONE_NUMBER_FONT_SIZE}
            chartConfig={chartConfig}
          />
          <CScoreBoneImageNumber
            leftPosition={opSideLeft ? 174 : 160}
            topPosition={BONE_NUMBER_TOP_POSITION}
            value={opSideLeft ? tibio.lateralValue : tibio.medialValue}
            fontSize={BONE_NUMBER_FONT_SIZE}
            chartConfig={chartConfig}
          />
          <CScoreBoneDirectionLabel
            leftPosition={113}
            topPosition={-25}
            label={'A'}
          />
          <CScoreBoneDirectionLabel
            leftPosition={248}
            topPosition={60}
            label={rightLabel.slice(0, 1)}
          />
          <CScoreBoneDirectionLabel
            leftPosition={111}
            topPosition={168}
            label={'P'}
          />
          <CScoreBoneDirectionLabel
            leftPosition={opSideLeft ? -29 : -24}
            topPosition={60}
            label={leftLabel.slice(0, 1)}
          />
        </Col>
        <Col span={1} />
        <Col span={8}>
          <Row>
            <Col flex={'auto'} style={{ textAlign: 'center' }}>
              <p style={labelStyle}>{rightLabel} Tibiofemoral Compartment</p>
            </Col>
          </Row>
          <Row>
            <Col flex={'auto'} style={{ textAlign: 'center' }}>
              <Bar
                {...createScoreConfig(
                  chartConfig,
                  opSideLeft ? tibio.lateralValue : tibio.medialValue,
                  12,
                  65,
                  330
                )}
              />
            </Col>
          </Row>
          <Row>
            <Col flex={'auto'} style={{ textAlign: 'center', marginTop: 5 }}>
              <Title level={4}>
                <Text style={{ color: tibioLateralColor }}>
                  {opSideLeft ? tibio.lateralLossText : tibio.medialLossText}
                </Text>{' '}
                Cartilage Loss
              </Title>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
export default CScoreInContextPage;
