import {
  MinusCircleOutlined,
  PlusCircleOutlined,
  UndoOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Input,
  Row,
  Select,
  Space,
  Table,
} from 'antd';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import config from '../../config';
import usePatientConsentsMutation from '../../hooks/mutations/usePatientConsentsMutation';
import { DateTimeFilterType } from '../../hooks/queries/useTreatmentPlanRequestsQuery';
import { FilterContext } from '../FilterContextProvider';
import { TenantContext } from '../TenantContextProvider';
import { TableRecord } from './types';
import useColumns from './useColumns';
import useIndexedTableRecords from './useIndexedTableRecords';
import styles from './TreatmentPlanRequestTable.module.css';
import { RangePickerProps } from 'antd/lib/date-picker';
import useLocaleSettings from '../../hooks/useLocaleSettings';
const { RangePicker } = DatePicker;
interface Props {
  onClickTreatmentPlanRequest: (record: TableRecord) => void;
}

// eslint-disable-next-line arrow-body-style
const disabledDate: RangePickerProps['disabledDate'] = (current) => {
  // Can not select days before configurated date
  return current && current < moment(config.datetimeFilterAllowedStartDate);
};

function TreatmentPlanRequestTable({ onClickTreatmentPlanRequest }: Props) {
  const locale = useLocaleSettings();
  const [page, setPage] = useState(1);

  // ---- Multi-Select for Grant and Revoke Patient Consent functionalities-----
  const grantPatientConsentMutation = usePatientConsentsMutation();
  const revokePatientConsentMutation = usePatientConsentsMutation();
  const [selectedTreatmentPlanRequestIds, setSelectedTreatmentPlanRequestIds] =
    useState<string[]>([]);

  const rowSelection = {
    selectedRowKeys: selectedTreatmentPlanRequestIds,
    onChange: (ids: React.Key[]) =>
      setSelectedTreatmentPlanRequestIds(ids as string[]),
  };
  const hasSelected = selectedTreatmentPlanRequestIds.length > 0;
  const createPatientConsentPayload = (moficaton: 'grant' | 'revoke') =>
    selectedTreatmentPlanRequestIds.reduce(
      (result, current) => ({
        ...result,
        [current]: moficaton === 'grant',
      }),
      {}
    );

  const consentModificationInProgress =
    revokePatientConsentMutation.isLoading ||
    grantPatientConsentMutation.isLoading;

  // --- Filters and filtering functionalities ----
  const { filters, dispatch, searchTerm, setSearchTerm } =
    useContext(FilterContext);

  const tenantContext = useContext(TenantContext);

  const [selectedRequestStates, setSelectedRequestStates] = useState<string[]>(
    filters.requestStates
  );

  const { isLoading, matchingIndexes, tableRecords } = useIndexedTableRecords({
    searchTerm,
    filters,
  });

  const columns = useColumns({
    matchingIndexes: matchingIndexes ?? {},
  });

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={8}>
          <Input.Group>
            <div style={{ display: 'flex', width: '100%' }}>
              <div style={{ flexGrow: 1 }}>
                <Select
                  style={{ width: '100%' }}
                  value={filters.dateTimeFilterType}
                  placeholder="Date Time Type"
                  onChange={(value: DateTimeFilterType) => {
                    if (value) {
                      dispatch({
                        type: 'set-date-time-filter-type',
                        value: value,
                      });
                    }
                  }}
                  allowClear={filters.dateTimeFilterType !== 'created-in'}
                  clearIcon={<UndoOutlined />}
                  onClear={() => {
                    dispatch({
                      type: 'set-date-time-filter-type',
                      value: 'created-in',
                    });
                  }}
                >
                  <Select.Option value={'created-in'}>
                    Request Created In
                  </Select.Option>
                  <Select.Option value={'surgery-starts-in'}>
                    Surgery Starts In
                  </Select.Option>
                </Select>
              </div>
              <div>
                <RangePicker
                  style={{ width: '100%', borderLeft: 'none' }}
                  value={[
                    filters.dateTimeStart.clone(),
                    filters.dateTimeEnd.clone(),
                  ]}
                  format={locale.dateFormat}
                  disabledDate={disabledDate}
                  allowClear={false}
                  onChange={(dateTime) => {
                    if (dateTime?.[0] && dateTime?.[1]) {
                      dispatch({
                        type: 'set-date-time-range',
                        value: {
                          start: dateTime[0],
                          end: dateTime[1],
                        },
                      });
                    }
                  }}
                />
              </div>
            </div>
          </Input.Group>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={6}>
          <Select
            allowClear
            mode="multiple"
            placeholder="Filter by Request State(s)"
            style={{ width: '100%' }}
            showArrow
            maxTagCount={1}
            value={selectedRequestStates}
            onDropdownVisibleChange={(open: boolean) => {
              // Trigger the backend filtering only when done selecting all request states
              if (open === false) {
                dispatch({
                  type: 'set-request-states',
                  value: (selectedRequestStates as string[]) ?? [],
                });
              }
            }}
            onClear={() => {
              setSelectedRequestStates([]);
              dispatch({ type: 'set-request-states', value: [] });
            }}
            onChange={(value: string[]) => {
              // Updates the list while dropdown is open
              setSelectedRequestStates(value);
            }}
          >
            {config.solidEligibleTprStates.map((solidEligibleTprState) => (
              <Select.Option
                key={solidEligibleTprState.name}
                value={solidEligibleTprState.name}
              >
                {solidEligibleTprState.displayName}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={10}>
          <Input
            placeholder="Search for everything..."
            allowClear
            value={searchTerm}
            onChange={(event: any) => {
              const query = event.currentTarget.value;
              setSearchTerm(query);
            }}
            disabled={isLoading}
          />
        </Col>
      </Row>
      {tenantContext?.tenant?.usePatientConsent && (
        <Row style={{ paddingTop: 32 }}>
          <Space>
            <Button
              type="primary"
              disabled={!hasSelected || consentModificationInProgress}
              loading={grantPatientConsentMutation.isLoading}
              onClick={() => {
                grantPatientConsentMutation.mutateAsync(
                  createPatientConsentPayload('grant')
                );
                setSelectedTreatmentPlanRequestIds([]);
              }}
              icon={<PlusCircleOutlined />}
            >
              Grant Consent
            </Button>
            <Button
              type="primary"
              loading={revokePatientConsentMutation.isLoading}
              disabled={!hasSelected || consentModificationInProgress}
              onClick={() => {
                revokePatientConsentMutation.mutateAsync(
                  createPatientConsentPayload('revoke')
                );
                setSelectedTreatmentPlanRequestIds([]);
              }}
              icon={<MinusCircleOutlined />}
            >
              Revoke Consent
            </Button>
            <span>
              {hasSelected
                ? `Selected ${
                    selectedTreatmentPlanRequestIds.length
                  } Treatment Plan Request${
                    selectedTreatmentPlanRequestIds.length > 1 ? 's' : ''
                  }`
                : ''}
            </span>
          </Space>
        </Row>
      )}
      <Table
        rowClassName={styles.row}
        scroll={{ x: true }}
        style={{ paddingTop: 16 }}
        loading={isLoading}
        dataSource={tableRecords}
        rowKey={(tableRecord) => tableRecord.treatmentPlanRequestId}
        columns={columns}
        onChange={(pagination) => {
          setPage(pagination.current ?? 1);
        }}
        pagination={{
          current: page,
          showSizeChanger: true,
        }}
        rowSelection={
          tenantContext?.tenant?.usePatientConsent ? rowSelection : undefined
        }
        onRow={(treatmentPlanRequest) => ({
          onClick: (event) => {
            if (window.getSelection()?.toString() || event.detail > 1) {
              return;
            }

            onClickTreatmentPlanRequest(treatmentPlanRequest);
          },
        })}
      />
    </>
  );
}

export default TreatmentPlanRequestTable;
