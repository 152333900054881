import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import {
  PostOperativeData,
  TreatmentPlanRequestsApi,
} from 'solid-workflow-service-typescript-axios';
import { TenantContext } from '../../components/TenantContextProvider';
import { TREATMENT_PLAN_REQUEST_QUERY_KEY } from '../../utils/consts';
import useSolidWorkflowService from '../useApiConfig';

interface Variables {
  treatmentPlanRequestId: string;
  postOperativeData: PostOperativeData;
}

function usePostOperativeDateMutation() {
  const tenantContext = useContext(TenantContext);
  const tenantId = tenantContext?.tenant?.id;

  const { createApiConfig } = useSolidWorkflowService();

  const queryClient = useQueryClient();

  const savePostOpData = ({
    treatmentPlanRequestId,
    postOperativeData,
  }: Variables) =>
    createApiConfig()
      .then((apiConfig) => new TreatmentPlanRequestsApi(apiConfig))
      .then(async (api) => {
        await api.treatmentPlanRequestsIdPostOperativeDataPut(
          treatmentPlanRequestId,
          tenantId ?? '',
          postOperativeData
        );

        return treatmentPlanRequestId;
      });

  return useMutation(savePostOpData, {
    onSuccess: (treatmentPlanRequestId) => {
      queryClient.invalidateQueries({
        queryKey: [
          TREATMENT_PLAN_REQUEST_QUERY_KEY,
          tenantId,
          treatmentPlanRequestId,
        ],
      });
    },
  });
}

export default usePostOperativeDateMutation;
