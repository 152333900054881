import { BarConfig } from '@ant-design/plots';
import { divide } from 'mathjs';
import { StrykerPerformanceIndicatorColor } from 'osep-react-antd';
import { SurgicalTime } from 'solid-workflow-service-typescript-axios';

export const GREEN = StrykerPerformanceIndicatorColor.GREEN;
export const YELLOW = StrykerPerformanceIndicatorColor.GOLD;
export const RED = StrykerPerformanceIndicatorColor.RED;

const PROCEDURE_TIME_BAR_HEIGHT = 140;
interface ScoreColorConfig {
  switchToYellow: number;
  switchToRed: number;
  min: number;
  max: number;
  reverse: boolean;
}

interface ProcedureTimeStats {
  predictedSurgicalTime: number;
  averageSurgicalTime: number;
  stdDeviation: number;
  numDeviations: number;
  distanceFromMean: number;
}

interface ProcedureTimeChartConfigValues {
  coloredBarStarts: number;
  chartMiddle: number;
  chartEnd: number;
  barColor: string;
}

function createScoreConfig(
  { min, max, switchToYellow, switchToRed, reverse }: ScoreColorConfig,
  scoreValue: any,
  axisFontSize?: number,
  chartHeight?: number,
  chartWidth?: number
) {
  const data = [
    {
      type: 'scoreData',
      value: reverse ? min : max,
    },
  ];

  const endGreen = divide(switchToYellow, reverse ? min : max) - 0.02;
  const startYellow = divide(switchToYellow, reverse ? min : max) + 0.02;
  const endYellow = divide(switchToRed, reverse ? min : max) - 0.02;
  const startRed = divide(switchToRed, reverse ? min : max) + 0.02;

  let displayValue = scoreValue;

  if (scoreValue > max) {
    displayValue = max;
  }

  if (scoreValue < min) {
    displayValue = min;
  }

  const config: BarConfig = {
    data: data.reverse(),
    height: chartHeight ?? 75,
    width: chartWidth ?? 400,
    color: `l(1) 0.01:${GREEN} ${endGreen}:${GREEN} ${startYellow}:${YELLOW} ${endYellow}:${YELLOW} ${startRed}:${RED} 1:${RED}`,
    xField: 'value',
    yField: 'type',
    coordinate: reverse
      ? [
          {
            type: 'reflectX',
          },
        ]
      : [],
    interactions: [{ type: 'tooltip', enable: false }],
    yAxis: {
      grid: null,
      label: null,
    },
    xAxis: {
      position: 'left',
      label: { style: { fontSize: axisFontSize ?? 13, color: '#eee' } },
      grid: { line: { style: { lineWidth: 2 } } },
    },
    annotations: [
      // Need an annotation for top and bottom halves of line
      {
        type: 'dataMarker',
        position: ['scoreData', displayValue],
        line: {
          length: chartHeight ? chartHeight / 3 : 25,
          style: {
            stroke: 'black',
            lineWidth: 4,
          },
        },
        point: null,
        direction: 'downward',
      },
      {
        type: 'dataMarker',
        position: ['scoreData', displayValue],
        line: {
          length: chartHeight ? chartHeight / 3 : 25,
          style: {
            stroke: 'black',
            lineWidth: 4,
          },
        },
        point: null,
      },
    ],
  };
  return config;
}

function getCScoreColor(
  config: ScoreColorConfig,
  score: number
): StrykerPerformanceIndicatorColor {
  const greenToYellow = config.switchToYellow;
  const yellowToRed = config.switchToRed;

  if (score <= greenToYellow && score > yellowToRed) {
    return YELLOW;
  }

  if (score > greenToYellow) return GREEN;

  return RED;
}

function getBScoreColor(
  config: ScoreColorConfig,
  score: number
): StrykerPerformanceIndicatorColor {
  let returnValue = RED;

  if (score < config.switchToYellow) {
    returnValue = GREEN;
  } else if (score < config.switchToRed) {
    returnValue = YELLOW;
  }
  return returnValue;
}

function getCScoreLossTextBasedOnScore(
  config: ScoreColorConfig,
  score: number
): 'Probable' | 'Possible' | 'Severe' {
  const greenToYellow = config.switchToYellow;
  const yellowToRed = config.switchToRed;

  if (score <= greenToYellow && score > yellowToRed) {
    return 'Probable';
  }

  if (score > greenToYellow) return 'Possible';

  return 'Severe';
}

function createProcedureTimeChartConfig(surgicalTime: SurgicalTime) {
  // NOTE: We are using a stacked bar chart from Ant Design charts. We create our own
  // axis using annotations, and the actual values of the bars are built by adding chunks
  // together based on the standard deviation, average, and predicted values.
  const { timeStats, chartConfigValues } =
    populateTimeStatsAndConfigValues(surgicalTime);
  const barData = createProcedureTimeBarData(timeStats, chartConfigValues);
  const annotations = createProcedureTimeAnnotations(
    timeStats,
    chartConfigValues
  );

  return {
    data: barData,
    annotations: annotations,
    height: PROCEDURE_TIME_BAR_HEIGHT,
    animate: false,
    isStack: true,
    xAxis: false,
    yAxis: false,
    xField: 'value',
    yField: 'data',
    seriesField: 'chartPiece',
    interactions: [{ type: 'tooltip', enable: false }],
    legend: false,
    label: {
      style: { fontSize: 14, fontWeight: 400 },
      content: ({ displayText }: any) => {
        return displayText;
      },
    },
    color: ({ chartPiece }: any) => {
      if (chartPiece === 'coloredBarChunk') {
        return chartConfigValues.barColor;
      }
      return '#f2f2f2'; // light grey
    },
    barStyle: {
      shadowColor: 'grey',
      shadowOffsetX: 1,
      shadowOffsetY: 1,
    },
    style: {
      marginTop: '5px',
      marginBottom: '10px',
    },
  };
}

// --------------- Private functions ----------------------
function populateTimeStatsAndConfigValues(surgicalTime: SurgicalTime): {
  timeStats: ProcedureTimeStats;
  chartConfigValues: ProcedureTimeChartConfigValues;
} {
  // Get the procedure time statistics from workflowResults
  const predictedTime = Math.round(surgicalTime.predictedDurationInMinutes);
  if (
    !surgicalTime.movingMeanLast10CasesInMinutes ||
    !surgicalTime.movingStdDevLast10CasesInMinutes
  ) {
    throw new Error(
      'ERROR: Cannot render chart without historical average or standard deviation.'
    );
  }
  const averageTime = Math.round(surgicalTime.movingMeanLast10CasesInMinutes);
  let timeStats: ProcedureTimeStats = {
    predictedSurgicalTime: predictedTime,
    averageSurgicalTime: averageTime,
    stdDeviation: Math.round(surgicalTime.movingStdDevLast10CasesInMinutes),
    numDeviations: 3,
    distanceFromMean: predictedTime - averageTime,
  };
  // Calculate chart config values for when Average < Predicted
  let chartConfigValues: ProcedureTimeChartConfigValues = {
    coloredBarStarts: timeStats.stdDeviation * timeStats.numDeviations,
    chartMiddle: timeStats.stdDeviation * timeStats.numDeviations,
    chartEnd:
      timeStats.stdDeviation * timeStats.numDeviations -
      timeStats.distanceFromMean,
    barColor:
      Math.abs(timeStats.distanceFromMean) > timeStats.stdDeviation
        ? RED
        : GREEN,
  };
  // Adjust for when Predicted < Average
  if (timeStats.predictedSurgicalTime < timeStats.averageSurgicalTime) {
    chartConfigValues.coloredBarStarts += timeStats.distanceFromMean;
    chartConfigValues.chartEnd += timeStats.distanceFromMean;
  }
  return { timeStats, chartConfigValues };
}

function createProcedureTimeBarData(
  timeStats: ProcedureTimeStats,
  chartConfigValues: ProcedureTimeChartConfigValues
) {
  const absDistanceFromMean = Math.abs(timeStats.distanceFromMean);
  const coloredBarPieceRatio = absDistanceFromMean / timeStats.stdDeviation;
  let distanceFromMeanDisplayText = '';

  // Checks if the colored bar piece is wide enough to include any label (minimum 0.36 required for "+XX", 0.25 required for "+X" )
  if (
    coloredBarPieceRatio > 0.36 ||
    (absDistanceFromMean < 10 && coloredBarPieceRatio > 0.25)
  ) {
    distanceFromMeanDisplayText =
      (timeStats.distanceFromMean > 0 ? '+' : '') +
      timeStats.distanceFromMean.toFixed(0);

    // Checks if the colored bar piece is wide enough to include minutes label (minimum 0.8 required for "+XX min", 0.6 required for "+X min")
    if (
      coloredBarPieceRatio > 0.8 ||
      (absDistanceFromMean < 10 && coloredBarPieceRatio > 0.6)
    ) {
      distanceFromMeanDisplayText += ' min';
    }
  }
  return [
    {
      data: 'procedureTime',
      value: chartConfigValues.coloredBarStarts,
      chartPiece: 'firstGreyBarChunk',
      displayText: '',
    },
    {
      data: 'procedureTime',
      value: Math.abs(timeStats.distanceFromMean),
      chartPiece: 'coloredBarChunk',
      displayText: distanceFromMeanDisplayText,
    },
    {
      data: 'procedureTime',
      value: chartConfigValues.chartEnd,
      chartPiece: 'secondGreyBarChunk',
      displayText: '',
    },
  ];
}

function createProcedureTimeAnnotations(
  timeStats: ProcedureTimeStats,
  chartConfigValues: ProcedureTimeChartConfigValues
) {
  let annotations = [
    // Initial annotations - necessary so typing works for rest of function
    {
      type: 'dataMarker',
      position: ['procedureTime', chartConfigValues.chartMiddle],
      line: {
        length: PROCEDURE_TIME_BAR_HEIGHT / 3.33,
        style: {
          stroke: 'grey',
          lineWidth: 4,
        },
      },
      direction: 'upward',
      point: null,
    },
    {
      type: 'text',
      position: [
        'procedureTime',
        chartConfigValues.chartMiddle - timeStats.stdDeviation / 2.7,
      ],
      content: 'Average',
      offsetY: PROCEDURE_TIME_BAR_HEIGHT / 2.2,
      point: null,
      style: { fill: 'grey', fontSize: 14, fontWeight: 580 },
    },
  ];

  for (let i = 1; i < 4; i++) {
    // Draw Average and Predicted lines
    let position =
      i / 2 < 1
        ? chartConfigValues.chartMiddle
        : chartConfigValues.chartMiddle + timeStats.distanceFromMean;
    annotations.push({
      type: 'dataMarker',
      position: ['procedureTime', position],
      line: {
        length:
          // Second half of average line, first, second halves of predicted line
          i === 1
            ? PROCEDURE_TIME_BAR_HEIGHT / 3.1
            : i === 2
            ? PROCEDURE_TIME_BAR_HEIGHT / 3.1
            : PROCEDURE_TIME_BAR_HEIGHT / 3.3,
        style: {
          stroke: i / 2 < 1 ? 'grey' : chartConfigValues.barColor,
          lineWidth: 4,
        },
      },
      direction: i % 2 === 0 ? 'upward' : 'downward',
      point: null,
    });
    // Draw Average and Predicted labels (including numbers below)
    const content =
      i % 2 === 0
        ? i < 1
          ? 'Average'
          : 'Predicted'
        : (i < 2
            ? timeStats.averageSurgicalTime
            : timeStats.predictedSurgicalTime
          ).toString();
    const numberLabelOffset =
      i % 2 === 0 ? timeStats.stdDeviation / 2.2 : timeStats.stdDeviation / 10;
    annotations.push({
      type: 'text',
      position: ['procedureTime', position - numberLabelOffset],
      content: content,
      // Average Number, "Predicted" , Predicted Number
      offsetY:
        i === 1
          ? PROCEDURE_TIME_BAR_HEIGHT / 2.65
          : i === 2
          ? -(PROCEDURE_TIME_BAR_HEIGHT / 2.2)
          : -(PROCEDURE_TIME_BAR_HEIGHT / 2.71),
      point: null,
      style: {
        fill: i / 2 < 1 ? 'grey' : chartConfigValues.barColor,
        fontSize: 14,
        fontWeight: 580,
      },
    });
  }
  for (
    let stdDevNum = timeStats.numDeviations * -1 + 1;
    stdDevNum < timeStats.numDeviations;
    stdDevNum++
  ) {
    if (stdDevNum === 0) continue;
    // Fill top and bottom half of grey std deviation lines
    for (let i = 0; i < 2; i++) {
      annotations.push({
        type: 'dataMarker',
        position: [
          'procedureTime',
          chartConfigValues.chartMiddle + stdDevNum * timeStats.stdDeviation,
        ],
        line: {
          length: PROCEDURE_TIME_BAR_HEIGHT / 3.33,
          style: {
            stroke: 'grey',
            lineWidth: 1,
          },
        },
        direction: i === 0 ? 'upward' : 'downward',
        point: null,
      });
    }
    // Add labels above and below std deviation lines
    const signChar = stdDevNum > 0 ? '+' : '';
    const stdDevNumberLabel = signChar + stdDevNum.toString() + ' std dev';
    const stdDevValueLabel = (
      timeStats.averageSurgicalTime +
      stdDevNum * timeStats.stdDeviation
    ).toString();
    for (let i = 0; i < 2; i++) {
      const labelOffset = i === 0 ? timeStats.stdDeviation / 3.3 : 0.35;
      annotations.push({
        type: 'text',
        position: [
          'procedureTime',
          chartConfigValues.chartMiddle +
            stdDevNum * timeStats.stdDeviation -
            labelOffset,
        ],
        content: i === 0 ? stdDevNumberLabel : stdDevValueLabel,
        offsetY: i === 0 ? 60 : 50,
        point: null,
        style: { fill: 'grey', fontSize: 10, fontWeight: 350 },
      });
    }
  }
  return annotations;
}

export {
  createScoreConfig,
  createProcedureTimeChartConfig,
  getBScoreColor,
  getCScoreColor,
  getCScoreLossTextBasedOnScore,
};
export type { ScoreColorConfig };
