import { Typography, Modal } from 'antd';
const { Title } = Typography;

interface Props {
  modalOpen: boolean;
  onClose: any;
}
const infotext = (
  <span>
    The surgical time prediction model provides an estimate of the duration of a
    Mako TKA surgery in minutes (skin-to-skin). The model is trained on features
    describing surgeon, surgical, and patient factors. These features include
    historical statistics of surgery duration, patient demographics, and
    disease-related measures such as the b-score (quantification of osteophyte
    progression) and joint space widths. The historical statistics of surgery
    duration are calculated for every surgeon and based on their last 10
    completed cases. The output of the model is intended to provide clinical
    guidance for improved surgery scheduling, and potentially indicate a case of
    increased complexity (e.g based on longer predicted surgical time).
  </span>
);

function ProcedureTimeMoreInfoModal({ modalOpen, onClose }: Props) {
  return (
    <Modal
      title={<Title level={3}>Procedure Time Information</Title>}
      open={modalOpen}
      onCancel={onClose}
      footer={null}
      width={1000}
    >
      <Title level={5} style={{ fontWeight: 375 }}>
        {infotext}
      </Title>
    </Modal>
  );
}

export default ProcedureTimeMoreInfoModal;
