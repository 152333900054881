import { useMutation } from '@tanstack/react-query';
import { useContext } from 'react';
import { WorkflowsApi } from 'solid-workflow-service-typescript-axios';
import { TenantContext } from '../../components/TenantContextProvider';
import useSolidWorkflowService from '../useApiConfig';

function useStartWorkflowMutation() {
  const { createApiConfig } = useSolidWorkflowService();
  const tenantContext = useContext(TenantContext);
  const tenantId = tenantContext?.tenant?.id;

  const startWorkflow = (treatmentPlanRequestId: string) =>
    createApiConfig()
      .then((apiConfig) => new WorkflowsApi(apiConfig))
      .then((api) =>
        api.workflowsTreatmentPlanRequestsIdPost(
          treatmentPlanRequestId,
          tenantId ?? ''
        )
      );

  return useMutation(startWorkflow);
}

export default useStartWorkflowMutation;
