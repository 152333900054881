import { WorkflowResults } from 'solid-workflow-service-typescript-axios';
import { Row, Image, Typography, Col } from 'antd';
import {
  ScoreColorConfig,
  getCScoreLossTextBasedOnScore,
} from '../../../../utils/chartConfigs';
import CScoreBoneImageNumber from './CScoreBoneImageNumber';
const { Text, Paragraph } = Typography;
interface Props {
  workflowResults: WorkflowResults;
  widgetVisible: boolean;
  operativeSide: string;
}
interface BoneProps {
  medialValue: number;
  lateralValue: number;
  medialLossText: 'Probable' | 'Possible' | 'Severe';
  lateralLossText: 'Probable' | 'Possible' | 'Severe';
}

const CConfig: ScoreColorConfig = {
  min: -3,
  max: 0,
  switchToYellow: -0.2,
  switchToRed: -2,
  reverse: true,
};

function CScoreSmallPreview({
  workflowResults: insightsResults,
  widgetVisible,
  operativeSide,
}: Props) {
  operativeSide = 'right';
  const opSideLeft = operativeSide === 'left';
  if (insightsResults.cScores && widgetVisible) {
    // ------------- Patellofemoral -------------
    const patello: BoneProps = {
      medialValue: insightsResults.cScores.medialPatellofemoral,
      lateralValue: insightsResults.cScores.lateralPatellofemoral,
      medialLossText: getCScoreLossTextBasedOnScore(
        CConfig,
        insightsResults.cScores.medialPatellofemoral
      ),
      lateralLossText: getCScoreLossTextBasedOnScore(
        CConfig,
        insightsResults.cScores.lateralPatellofemoral
      ),
    };
    // ------------- Tibiofemoral -------------
    const tibio: BoneProps = {
      medialValue: insightsResults.cScores.medialTibiofemoral,
      lateralValue: insightsResults.cScores.lateralTibiofemoral,
      medialLossText: getCScoreLossTextBasedOnScore(
        CConfig,
        insightsResults.cScores.medialTibiofemoral
      ),
      lateralLossText: getCScoreLossTextBasedOnScore(
        CConfig,
        insightsResults.cScores.lateralTibiofemoral
      ),
    };

    return (
      <div
        style={{
          border: '1px solid lightgrey',
          margin: '0.5rem -0.5rem 0 0rem',
          padding: '0.5rem',
        }}
      >
        <Row style={{ fontWeight: 500 }}>
          <Col>C-Score:</Col>
        </Row>
        <Row>
          <Col span={13}>
            <Paragraph
              style={{
                textAlign: 'center',
                margin: '0px 0px 5px -38px',
                fontSize: 14,
              }}
            >
              Patellofemoral
            </Paragraph>
            <Image
              src={
                process.env.PUBLIC_URL +
                '/cscore/patellofemoral/' +
                operativeSide.toLowerCase() +
                '_operative_side/' +
                patello.medialLossText +
                '_' +
                patello.lateralLossText +
                '.png'
              }
              alt={'Patellofemoral Image'}
              preview={false}
              width="105px"
            ></Image>
            {/* Left Side Number */}
            <CScoreBoneImageNumber
              leftPosition={20}
              topPosition={56}
              value={opSideLeft ? patello.medialValue : patello.lateralValue}
              fontSize={14}
              chartConfig={CConfig}
            />
            {/* Right Side Number */}
            <CScoreBoneImageNumber
              leftPosition={65}
              topPosition={56}
              value={opSideLeft ? patello.lateralValue : patello.medialValue}
              fontSize={14}
              chartConfig={CConfig}
            />
          </Col>
          {/* ---- Tibiofemoral Image ---- */}
          <Col span={11}>
            <Text style={{ marginLeft: 15, fontSize: 14 }}>Tibiofemoral</Text>
            <Image
              src={
                process.env.PUBLIC_URL +
                '/cscore/tibiofemoral/' +
                operativeSide.toLowerCase() +
                '_operative_side/' +
                tibio.medialLossText +
                '_' +
                tibio.lateralLossText +
                '.png'
              }
              style={{ marginLeft: -10, marginTop: 7 }}
              alt={'Tibiofemoral Image'}
              preview={false}
              width="120px"
            ></Image>
            {/* Left Side Number */}
            <CScoreBoneImageNumber
              leftPosition={opSideLeft ? 7 : 2}
              value={opSideLeft ? tibio.medialValue : tibio.lateralValue}
              fontSize={14}
              topPosition={56}
              chartConfig={CConfig}
            />
            {/* Right Side Number */}
            <CScoreBoneImageNumber
              leftPosition={opSideLeft ? 79 : 73}
              value={opSideLeft ? tibio.lateralValue : tibio.medialValue}
              fontSize={14}
              topPosition={56}
              chartConfig={CConfig}
            />
          </Col>
        </Row>
      </div>
    );
  } else {
    return <div />;
  }
}

export default CScoreSmallPreview;
export type { BoneProps };
