import { Result } from 'antd';
import CenteredPageLayout from './CenteredPageLayout';

interface Props {
  error: string;
  title?: string;
}

function ErrorPage({ error, title = 'A Problem Occurred' }: Props) {
  return (
    <CenteredPageLayout>
      <Result status="error" title={title} subTitle={error} />
    </CenteredPageLayout>
  );
}

export default ErrorPage;
