import { Card, Col, Divider, Row, Typography } from 'antd';
import {
  TreatmentPlanRequestEntity,
  WorkflowExecutionStatusEnum,
} from 'solid-workflow-service-typescript-axios';
import findLatestWorkflowExection from '../../utils/findLatestWorkflowExecution';
import BScoreSmallPreview from '../Insights/KneeInsights/BScore/BScoreSmallPreview';
import CScoreSmallPreview from '../Insights/KneeInsights/CScore/CScoreSmallPreview';
import InsightsMissingPopover from './InsightsMissingPopover';
import OpenBoneQualityInsightsButton from './OpenBoneQualityInsightsButton';
import OpenFullLegInsightsButton from './OpenFullLegInsightsButton';
import OpenKneeInsightsButton from './OpenKneeInsightsButton';
import WorkflowStatusTag from './WorkflowStatusTag';
import { WorkflowStatus } from '../TreatmentPlanRequestTable/types';
import { toDisplayArthimeticHipKneeAnkle } from '../Insights/FullLegInsights/ArithmeticHipKneeAnkle';
const { Title, Text } = Typography;

interface Props {
  treatmentPlanRequest: TreatmentPlanRequestEntity;
  workflowStatus: WorkflowStatus;
}

const insightMissing: WorkflowStatus = {
  displayValue: 'Error',
  status: WorkflowExecutionStatusEnum.Error,
};
function InsightsSummaryCard({ treatmentPlanRequest, workflowStatus }: Props) {
  const latestWorkflowExecution = findLatestWorkflowExection(
    treatmentPlanRequest?.workflowExecutions
  );
  const boneQualityThresholds =
    latestWorkflowExecution?.results?.surfaces?.boneQualityThresholds ?? [];

  return (
    <Card
      style={{ height: '98%', width: '93%', marginLeft: '1vw' }}
      title={<span style={{ fontSize: 17 }}>Insights Summary</span>}
      extra={
        <WorkflowStatusTag
          workflowStatus={workflowStatus}
          large
          latestResults={latestWorkflowExecution?.results}
          checkTotalCompletion
        />
      }
      bodyStyle={{ height: 'calc(100% - 57px)' }}
    >
      <Row>
        {/* Knee Insights Section */}
        <Col xs={24} sm={11}>
          <Row>
            <Col flex={15}>
              <Title level={5}>Knee Insights</Title>
            </Col>
            <Col>
              <WorkflowStatusTag workflowStatus={workflowStatus} />
            </Col>
          </Row>
          {latestWorkflowExecution?.results?.surgicalTimeCemented && (
            <div
              style={{
                border: '1px solid lightgrey',
                margin: '0rem -0.5rem 0 0rem',
                padding: '0.5rem',
              }}
            >
              <Row>
                <Col style={{ fontWeight: 500 }}>
                  Predicted Procedure Time:{' '}
                </Col>
              </Row>
              <Row>
                <Col flex={12}>
                  Cemented:{' '}
                  <Text style={{ fontWeight: 700 }}>
                    {latestWorkflowExecution?.results?.surgicalTimeCemented.predictedDurationInMinutes.toFixed(
                      0
                    )}
                  </Text>{' '}
                  min
                </Col>
                <Col flex={12}>
                  Cementless:{' '}
                  <Text style={{ fontWeight: 700 }}>
                    {latestWorkflowExecution?.results?.surgicalTimeCementless.predictedDurationInMinutes.toFixed(
                      0
                    )}
                  </Text>{' '}
                  min
                </Col>
              </Row>
            </div>
          )}
          <Row style={{ marginTop: '0.5rem' }}></Row>
          {latestWorkflowExecution?.results?.bScore && (
            <BScoreSmallPreview
              workflowResults={latestWorkflowExecution.results}
            />
          )}
          <Row style={{ marginTop: '0.5rem' }}></Row>
          {latestWorkflowExecution?.results?.cScores && (
            <CScoreSmallPreview
              workflowResults={latestWorkflowExecution.results}
              operativeSide={
                treatmentPlanRequest.treatment?.operativeSide ?? ''
              }
              widgetVisible={true}
            />
          )}

          <Row align="middle" justify="center" style={{ height: '6rem' }}>
            <OpenKneeInsightsButton
              treatmentPlanRequest={treatmentPlanRequest}
            />
          </Row>
        </Col>
        <Col span={2}>
          {' '}
          <Divider
            type="vertical"
            style={{ height: '100%', marginLeft: '2rem' }}
          />
        </Col>
        <Col xs={24} sm={11}>
          {/* Full Leg Insights Section */}
          <Row>
            <Col flex={16}>
              <Title level={5}>Full Leg Insights</Title>
            </Col>
            <Col>
              {/* Show an error if the full leg insights are missing */}
              <InsightsMissingPopover
                insightName="full leg"
                insightProperty={
                  latestWorkflowExecution?.results
                    ?.fullLegArithmeticHipKneeAnkle
                }
                workflowStatus={workflowStatus}
              >
                <WorkflowStatusTag
                  workflowStatus={
                    workflowStatus.status ===
                      WorkflowExecutionStatusEnum.Completed &&
                    !latestWorkflowExecution?.results
                      ?.fullLegArithmeticHipKneeAnkle
                      ? insightMissing
                      : workflowStatus
                  }
                />
              </InsightsMissingPopover>
            </Col>
          </Row>
          {latestWorkflowExecution?.results?.fullLegArithmeticHipKneeAnkle && (
            <Row>
              Arthimetic Hip-Knee-Ankle (aHKA):{' '}
              {toDisplayArthimeticHipKneeAnkle(
                latestWorkflowExecution.results.fullLegArithmeticHipKneeAnkle
              )}
            </Row>
          )}
          <Row align="middle" justify="center" style={{ height: '6rem' }}>
            <OpenFullLegInsightsButton
              treatmentPlanRequest={treatmentPlanRequest}
            />
          </Row>
          <Divider></Divider>
          <Row>
            <Col flex={16}>
              <Title level={5}>Bone Quality Display</Title>
            </Col>
            <Col>
              {/* Show an error if the bone quality insights are missing */}
              <InsightsMissingPopover
                insightName="bone quality"
                insightProperty={
                  latestWorkflowExecution?.results?.surfaces
                    ?.boneQualityThresholds
                }
                workflowStatus={workflowStatus}
              >
                <WorkflowStatusTag
                  workflowStatus={
                    workflowStatus.status ===
                      WorkflowExecutionStatusEnum.Completed &&
                    boneQualityThresholds.length !== 5
                      ? insightMissing
                      : workflowStatus
                  }
                />
              </InsightsMissingPopover>
            </Col>
          </Row>
          <Row align="middle" justify="center" style={{ height: '6rem' }}>
            <OpenBoneQualityInsightsButton
              treatmentPlanRequest={treatmentPlanRequest}
            />
          </Row>
        </Col>
      </Row>
    </Card>
  );
}

export default InsightsSummaryCard;
