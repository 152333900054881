import { useState, createContext, useContext, useEffect } from 'react';
import { Tenant } from 'solid-workflow-service-typescript-axios';
import useTenantsQuery from '../hooks/queries/useTenantsQuery';
import { UserContext } from './UserContextProvider';

const TENANT_ID_KEY = 'tenantId';

interface Value {
  availableTenants: Tenant[];
  tenant?: Tenant;
  setTenant: (id: string) => void;
}

export const TenantContext = createContext<Value | undefined>(undefined);

const TenantContextProvider: React.FC = ({ children }) => {
  const user = useContext(UserContext);
  const { data: availableTenants } = useTenantsQuery();

  const [value, setValue] = useState<Value>();
  const [tenantId, setTenant] = useState(localStorage.getItem(TENANT_ID_KEY));

  useEffect(() => {
    if (!user || !availableTenants) {
      return;
    }

    setValue({
      availableTenants,
      tenant: availableTenants.find((t) => t.id === tenantId),
      setTenant: (id: string) => {
        localStorage.setItem(TENANT_ID_KEY, id);
        setTenant(id);
      },
    });
  }, [availableTenants, tenantId, user]);

  return (
    <TenantContext.Provider value={value}>{children}</TenantContext.Provider>
  );
};

export default TenantContextProvider;
