import { useLoader } from '@react-three/fiber';
import {
  WorkflowResultsSurfaces,
  WorkflowResultsSurfacesBoneQualityThresholds,
} from 'solid-workflow-service-typescript-axios';
import { FrontSide } from 'three';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import config from '../../../config';
interface Props {
  accessToken: string;
  surfaces: WorkflowResultsSurfaces;
  boneQualityThresholds: WorkflowResultsSurfacesBoneQualityThresholds[];
  tenantId: string;
  showTibia: boolean;
  showFibula: boolean;
  thresholdNumber: number;
}

const BONE_COLOR = '#f4efaf';
const OUTSIDE_BONE_OPACITY = 0.42;

const baseMeshProps = {
  emissive: 0x000000,
  roughness: 0.5,
  metalness: 0,
  flatShading: false,
  wireframe: false,
  vertexColors: false,
  fog: false,
  depthTest: true,
  depthWrite: true,
  alphaTest: 0,
  visible: true,
  side: FrontSide,
  transparent: true,
};

function BoneQualityModel({
  surfaces,
  boneQualityThresholds,
  accessToken,
  tenantId,
  showTibia,
  showFibula,
  thresholdNumber,
}: Props) {
  const itemIdToUrl = (itemId?: string) => {
    if (!itemId) {
      return `${process.env.PUBLIC_URL}/empty.stl`;
    }
    return `${config.solidBasePath}/api/v1/items/${itemId}/download`;
  };

  const [
    fibulaGeom,
    tibiaGeom,
    threshold1Geom,
    threshold2Geom,
    threshold3Geom,
    threshold4Geom,
    threshold5Geom,
  ] = useLoader(
    STLLoader,
    [
      surfaces.fibulaItemId,
      surfaces.tibiaBoneOnlyItemId,
      boneQualityThresholds[0].itemId,
      boneQualityThresholds[1].itemId,
      boneQualityThresholds[2].itemId,
      boneQualityThresholds[3].itemId,
      boneQualityThresholds[4].itemId,
    ].map(itemIdToUrl),
    (loader) => {
      loader.setRequestHeader({
        Authorization: `Bearer ${accessToken}`,
        tenant_id: tenantId,
      });
    }
  );

  return (
    <>
      <mesh key={fibulaGeom.uuid} rotation-x={-(Math.PI / 2)}>
        <primitive object={fibulaGeom} attach="geometry" />
        <meshStandardMaterial
          color={BONE_COLOR}
          {...baseMeshProps}
          visible={showFibula}
          opacity={OUTSIDE_BONE_OPACITY}
        />
      </mesh>
      <mesh key={threshold1Geom.uuid} rotation-x={-(Math.PI / 2)}>
        <primitive object={threshold1Geom} attach="geometry" />
        <meshStandardMaterial
          color={BONE_COLOR}
          {...baseMeshProps}
          visible={thresholdNumber === 150}
        />
      </mesh>
      <mesh key={threshold2Geom.uuid} rotation-x={-(Math.PI / 2)}>
        <primitive object={threshold2Geom} attach="geometry" />
        <meshStandardMaterial
          color={BONE_COLOR}
          {...baseMeshProps}
          visible={thresholdNumber === 175}
        />
      </mesh>
      <mesh key={threshold3Geom.uuid} rotation-x={-(Math.PI / 2)}>
        <primitive object={threshold3Geom} attach="geometry" />
        <meshStandardMaterial
          color={BONE_COLOR}
          {...baseMeshProps}
          visible={thresholdNumber === 200}
        />
      </mesh>
      <mesh key={threshold4Geom.uuid} rotation-x={-(Math.PI / 2)}>
        <primitive object={threshold4Geom} attach="geometry" />
        <meshStandardMaterial
          color={BONE_COLOR}
          {...baseMeshProps}
          visible={thresholdNumber === 225}
        />
      </mesh>
      <mesh key={threshold5Geom.uuid} rotation-x={-(Math.PI / 2)}>
        <primitive object={threshold5Geom} attach="geometry" />
        <meshStandardMaterial
          color={BONE_COLOR}
          {...baseMeshProps}
          visible={thresholdNumber === 250}
        />
      </mesh>
      <mesh key={tibiaGeom.uuid} rotation-x={-(Math.PI / 2)}>
        <primitive object={tibiaGeom} attach="geometry" />
        <meshStandardMaterial
          color={BONE_COLOR}
          {...baseMeshProps}
          visible={showTibia}
          opacity={OUTSIDE_BONE_OPACITY}
        />
      </mesh>
    </>
  );
}

export default BoneQualityModel;
