import React, { memo, useCallback } from 'react';

import debounce from 'lodash.debounce';
import { useFormContext, useWatch } from 'react-hook-form';
import useDeepCompareEffect from 'use-deep-compare-effect';

type Props = {
  defaultValues: any;
  onSubmit: any;
};

const AutoSave = memo(({ defaultValues, onSubmit }: Props) => {
  const { control, handleSubmit, formState } = useFormContext();

  const debouncedSave = useCallback(
    debounce(() => {
      handleSubmit(onSubmit)();
    }, 1000),
    []
  );

  const watchedData = useWatch({
    control: control,
    defaultValue: defaultValues,
  });

  useDeepCompareEffect(() => {
    if (formState.isDirty) {
      debouncedSave();
    }
  }, [watchedData]);

  return <></>;
});

export default AutoSave;
