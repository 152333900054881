import { Tabs, TabsProps } from 'antd';
import { useNavigate } from 'react-router-dom';
import {
  WorkflowExecution,
  WorkflowExecutionStatusEnum,
} from 'solid-workflow-service-typescript-axios';

interface Props {
  pagePath: InsightPagePath;
  latestWorkflowExecution?: WorkflowExecution;
}

export type InsightPagePath =
  | 'knee-insights'
  | 'full-leg-insights'
  | 'bone-quality-insights';

function InsightsPagesTabs({ pagePath, latestWorkflowExecution }: Props) {
  const navigate = useNavigate();
  const onTabClick = (key: string) => {
    navigate(`../${key}`);
  };
  const tabItems: TabsProps['items'] = [
    {
      key: 'knee-insights',
      label: <div style={{ fontSize: '1.15rem' }}>Knee Insights</div>,
      disabled:
        latestWorkflowExecution?.status !==
        WorkflowExecutionStatusEnum.Completed,
    },
    {
      key: 'full-leg-insights',
      label: <div style={{ fontSize: '1.15rem' }}>Full Leg Insights</div>,
      disabled:
        latestWorkflowExecution?.status !==
          WorkflowExecutionStatusEnum.Completed ||
        !latestWorkflowExecution?.results?.fullLegArithmeticHipKneeAnkle,
    },
    {
      key: 'bone-quality-insights',
      label: <div style={{ fontSize: '1.15rem' }}>Bone Quality Insights</div>,
      disabled:
        latestWorkflowExecution?.status !==
          WorkflowExecutionStatusEnum.Completed ||
        latestWorkflowExecution?.results?.surfaces?.boneQualityThresholds
          ?.length !== 5,
    },
  ];

  return (
    <Tabs
      defaultActiveKey={pagePath}
      items={tabItems}
      onChange={onTabClick}
      style={{ paddingTop: '0.7rem' }}
    ></Tabs>
  );
}

export default InsightsPagesTabs;
