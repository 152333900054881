import { Bar } from '@ant-design/plots';
import { Col, Image, Row, Typography } from 'antd';
import { GREEN, RED, YELLOW } from '../../../../utils/chartConfigs';
const { Title, Text } = Typography;

interface Props {
  chartConfig: any;
  bScore: number;
  bScoreAdjusted: number;
  bScoreDisplay: string;
}
// B-Score Examples, Images, and Labels config constants
const MILD_B_SCORE = 0.0;
const MODERATE_B_SCORE = 3.0;
const SEVERE_B_SCORE = 7.0;
const SAMPLE_WIDTH = '190px';
const ACTUAL_WIDTH = '270px';
const TOP_LABEL_LEVEL = 4;
const BOTTOM_LABEL_LEVEL = 4;
const TOP_LABEL_X_OFFSET = '-20px';
const BOTTOM_LABEL_X_OFFSET = '-30px';

function BScoreReferenceExamplesPage({
  chartConfig,
  bScore,
  bScoreAdjusted,
  bScoreDisplay,
}: Props) {
  // Initialize B-Score Example Images and Labels
  let bScoreExamples = [MILD_B_SCORE, MODERATE_B_SCORE, SEVERE_B_SCORE];
  let bScoreAdjustedImgRefs = [MILD_B_SCORE, MODERATE_B_SCORE, SEVERE_B_SCORE];
  let bScoreLabels = ['Mild', 'Moderate', 'Severe'];
  let bScoreTopLabelColors = ['black', 'black', 'black'];
  let bScoreTopLabelNumberColors = [GREEN, YELLOW, RED, ''];
  let bScoreBottomLabelColors = [GREEN, YELLOW, RED, ''];

  // Insert the Patient's B-Score Image and Label where it fits into the arrays
  const insertIndex = findInsertIndex(bScoreExamples, bScore);
  bScoreExamples.splice(insertIndex, 0, bScore);
  bScoreAdjustedImgRefs.splice(insertIndex, 0, bScoreAdjusted);
  bScoreLabels.splice(insertIndex, 0, '');
  bScoreTopLabelColors.splice(insertIndex, 0, 'black');
  bScoreTopLabelNumberColors.splice(insertIndex, 0, 'black');
  bScoreBottomLabelColors.splice(insertIndex, 0, 'black');

  // Build up the HTML elements
  let bScoreHtmlElements: any[] = [];
  bScoreExamples.forEach((element, index) => {
    // Makes the Patient's B-Score bolder to add emphasis
    const numberBoldness = element === bScore ? 750 : 650;
    bScoreHtmlElements.push(
      <Col flex="auto">
        {/* Top Label - B-Score: X.X */}
        <Row>
          <Col flex="auto" style={{ textAlign: 'center' }}>
            <Title
              style={{
                color: bScoreTopLabelColors[index],
                marginLeft: TOP_LABEL_X_OFFSET,
                fontWeight: 450,
              }}
              level={TOP_LABEL_LEVEL}
            >
              {bScoreLabels[index] ? '' : "Patient's"} B-Score:{' '}
              <Text
                style={{
                  color: bScoreTopLabelNumberColors[index],
                  fontWeight: numberBoldness,
                }}
              >
                {element === bScoreAdjustedImgRefs[index]
                  ? element.toFixed(1)
                  : bScoreDisplay}
              </Text>
            </Title>
          </Col>
        </Row>
        {/* B-Score Image */}
        <Row>
          <Image
            src={
              process.env.PUBLIC_URL +
              '/bscore/bscore-' +
              bScoreAdjustedImgRefs[index].toFixed(1) +
              '.png'
            }
            alt={bScoreLabels[index] + ' B-Score'}
            preview={false}
            width={bScoreLabels[index] ? SAMPLE_WIDTH : ACTUAL_WIDTH}
          ></Image>
        </Row>
        {/* Bottom Label */}
        <Row>
          <Col flex="auto" style={{ textAlign: 'center' }}>
            <Title
              style={{
                color: bScoreBottomLabelColors[index],
                marginLeft: BOTTOM_LABEL_X_OFFSET,
              }}
              level={BOTTOM_LABEL_LEVEL}
            >
              {bScoreLabels[index]}
            </Title>
          </Col>
        </Row>
      </Col>
    );
  });
  return (
    <div>
      <Title level={4} style={{ fontWeight: 375 }}>
        Anything above green means osteoarthritis in the femur. Images are
        representations of B-Score only.
      </Title>
      <Bar {...chartConfig} />
      <Row style={{ marginTop: '25px' }}>{bScoreHtmlElements}</Row>
    </div>
  );
}

// Returns Index where number fits in a sorted array
function findInsertIndex(array: number[], value: number) {
  let low = 0,
    high = array.length;
  while (low < high) {
    let mid = (low + high) >>> 1;
    if (array[mid] < value) low = mid + 1;
    else high = mid;
  }
  return low;
}
export default BScoreReferenceExamplesPage;
