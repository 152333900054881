import { Avatar, Card, Space } from 'antd';
import Meta from 'antd/lib/card/Meta';
import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { TenantContext } from '../components/TenantContextProvider';
import CenteredPageLayout from './CenteredPageLayout';
import ErrorPage from './ErrorPage';

const TenantSelectionPage: React.FC = () => {
  const tenantContext = useContext(TenantContext);

  if (!tenantContext) {
    return <></>;
  }

  const { tenant, availableTenants, setTenant } = tenantContext;

  if (tenant) {
    return <Navigate to={tenant.id} />;
  } else if (availableTenants.length === 0) {
    return (
      <ErrorPage error="Your user is not setup to use this application." />
    );
  } else if (availableTenants.length === 1) {
    return <Navigate to={availableTenants[0].id} />;
  }

  return (
    <CenteredPageLayout>
      <Space style={{ textAlign: 'left' }}>
        {availableTenants.map((t) => (
          <Card
            key={t.id}
            style={{ width: 300 }}
            onClick={() => setTenant(t.id)}
            hoverable={true}
          >
            <Meta
              avatar={<Avatar size="large">{t.name}</Avatar>}
              title={t.name}
              description={`Use application for ${t.name}`}
            />
          </Card>
        ))}
      </Space>
    </CenteredPageLayout>
  );
};

export default TenantSelectionPage;
