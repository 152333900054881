import {
  createContext,
  Dispatch,
  SetStateAction,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { Filters } from '../hooks/queries/useTreatmentPlanRequestsQuery';
import moment from 'moment';
import filterReducer, {
  Action,
} from './TreatmentPlanRequestTable/filterReducer';

interface Value {
  filters: Filters;
  dispatch: Dispatch<Action>;
  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
}
const initialFilters: Filters = {
  requestStates: [],
  dateTimeFilterType: 'created-in',
  dateTimeStart: moment().subtract(1, 'months'),
  dateTimeEnd: moment(),
};

const initialValue: Value = {
  filters: initialFilters,
  dispatch: () => {},
  searchTerm: '',
  setSearchTerm: () => {},
};
export const FilterContext = createContext<Value>(initialValue);

const FilterContextProvider: React.FC = ({ children }) => {
  const [filters, dispatch] = useReducer(filterReducer, initialFilters);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const value: Value = useMemo(
    () => ({ filters, dispatch, searchTerm, setSearchTerm }),
    [filters, dispatch, searchTerm, setSearchTerm]
  );

  return (
    <FilterContext.Provider value={value}>{children}</FilterContext.Provider>
  );
};

export default FilterContextProvider;
