import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';

import { TreatmentPlanRequestsApi } from 'solid-workflow-service-typescript-axios';
import { TenantContext } from '../../components/TenantContextProvider';
import { TREATMENT_PLAN_REQUEST_QUERY_KEY } from '../../utils/consts';
import useSolidWorkflowService from '../useApiConfig';

function useTreatmentPlanRequestQuery(treatmentPlanRequestId: string) {
  const { createApiConfig } = useSolidWorkflowService();
  const tenantContext = useContext(TenantContext);
  const tenantId = tenantContext?.tenant?.id;

  return useQuery(
    [TREATMENT_PLAN_REQUEST_QUERY_KEY, tenantId, treatmentPlanRequestId],
    () =>
      createApiConfig()
        .then((apiConfig) => new TreatmentPlanRequestsApi(apiConfig))
        .then((api) =>
          api.treatmentPlanRequestsIdGet(treatmentPlanRequestId, tenantId ?? '')
        )
        .then((response) => response.data)
  );
}

export default useTreatmentPlanRequestQuery;
