import React from 'react';
import { ScoreColorConfig } from '../../../../utils/chartConfigs';
interface Props {
  leftPosition: number;
  topPosition?: number;
  value: number;
  fontSize?: number;
  chartConfig: ScoreColorConfig;
}

function CScoreBoneImageNumber({
  leftPosition,
  topPosition,
  value,
  fontSize,
  chartConfig: { min, max },
}: Props) {
  let displayValue = value.toFixed(1);
  let left = leftPosition;

  if (value > max) {
    displayValue = max.toFixed(1);
  }

  if (value < min) {
    displayValue = '<' + min.toFixed(1);
    left = leftPosition - 10;
  }

  return (
    <p
      style={{
        fontSize: fontSize ?? 24 + 'px',
        color: 'black',
        fontWeight: 500,
        position: 'absolute',
        top: topPosition ?? 78,
        left,
      }}
    >
      {displayValue}
    </p>
  );
}
export default CScoreBoneImageNumber;
