import { useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import FilterContextProvider from './components/FilterContextProvider';
import { TenantContext } from './components/TenantContextProvider';
import TenantGate from './components/TenantGate';
import InsightsPage from './pages/KneeInsightsPage';
import TenantSelectionPage from './pages/TenantSelectionPage';
import TreatmentPlanRequestDetailsPage from './pages/TreatmentPlanRequestDetailsPage';
import TreatmentPlanRequestsPage from './pages/TreatmentPlanRequestsPage';
import FullLegInsightsPage from './pages/FullLegInsightsPage';
import BoneQualityInsightsPage from './pages/BoneQualityInsightsPage';

function App() {
  const tenantContext = useContext(TenantContext);

  if (!tenantContext) {
    return <></>;
  }

  return (
    <Routes>
      <Route path="/" element={<TenantSelectionPage />} />
      <Route
        path=":tenantId/*"
        element={
          <TenantGate>
            <FilterContextProvider>
              <Routes>
                <Route path="/" element={<TreatmentPlanRequestsPage />} />
                <Route path="treatment-plan-request/:treatmentPlanRequestId/*">
                  <Route
                    path=""
                    element={<TreatmentPlanRequestDetailsPage />}
                  />
                  <Route path="knee-insights" element={<InsightsPage />} />
                  <Route
                    path="full-leg-insights"
                    element={<FullLegInsightsPage />}
                  />
                  <Route
                    path="bone-quality-insights"
                    element={<BoneQualityInsightsPage />}
                  />
                </Route>
              </Routes>
            </FilterContextProvider>
          </TenantGate>
        }
      />
    </Routes>
  );
}

export default App;
