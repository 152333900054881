import React, { CSSProperties, useState } from 'react';
import { WorkflowResults } from 'solid-workflow-service-typescript-axios';
import { Button, Card, Row, Image, Typography, Col } from 'antd';
import {
  ScoreColorConfig,
  getCScoreLossTextBasedOnScore,
} from '../../../../utils/chartConfigs';
import {
  DoubleLeftOutlined,
  DoubleRightOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import InsightsModal from '../../InsightsModal';
import CScoreInContextPage from './CScoreInContextPage';
import CScoreBoneImageNumber from './CScoreBoneImageNumber';
import CScoreDefinitionMoreInfoPage from './CScoreDefinitionMoreInfoPage';
const { Text, Title, Paragraph } = Typography;
interface Props {
  workflowResults: WorkflowResults;
  widgetVisible: boolean;
  operativeSide: string;
  style?: CSSProperties;
}
interface BoneProps {
  medialValue: number;
  lateralValue: number;
  medialLossText: 'Probable' | 'Possible' | 'Severe';
  lateralLossText: 'Probable' | 'Possible' | 'Severe';
}

const CConfig: ScoreColorConfig = {
  min: -3,
  max: 0,
  switchToYellow: -0.2,
  switchToRed: -2,
  reverse: true,
};

function CScore({
  workflowResults: insightsResults,
  widgetVisible,
  operativeSide,
  style,
}: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inContextPageShown, setInContextPageShown] = useState(true);
  const opSideLeft = operativeSide === 'Left';
  if (insightsResults.cScores && widgetVisible) {
    // ------------- Patellofemoral -------------
    const patello: BoneProps = {
      medialValue: insightsResults.cScores.medialPatellofemoral,
      lateralValue: insightsResults.cScores.lateralPatellofemoral,
      medialLossText: getCScoreLossTextBasedOnScore(
        CConfig,
        insightsResults.cScores.medialPatellofemoral
      ),
      lateralLossText: getCScoreLossTextBasedOnScore(
        CConfig,
        insightsResults.cScores.lateralPatellofemoral
      ),
    };
    // ------------- Tibiofemoral -------------
    const tibio: BoneProps = {
      medialValue: insightsResults.cScores.medialTibiofemoral,
      lateralValue: insightsResults.cScores.lateralTibiofemoral,
      medialLossText: getCScoreLossTextBasedOnScore(
        CConfig,
        insightsResults.cScores.medialTibiofemoral
      ),
      lateralLossText: getCScoreLossTextBasedOnScore(
        CConfig,
        insightsResults.cScores.lateralTibiofemoral
      ),
    };

    return (
      <Card
        style={style}
        title={<Title level={5}>C-Score</Title>}
        extra={
          <Button
            icon={<InfoCircleOutlined style={{ fontSize: '1.2rem' }} />}
            type="text"
            onClick={() => setIsModalOpen(true)}
          ></Button>
        }
      >
        <Row>
          <Col span={13}>
            <Paragraph
              style={{
                textAlign: 'center',
                marginTop: 0,
                marginRight: 0,
                marginBottom: '5px',
                marginLeft: '-45px',
                fontSize: 16,
              }}
            >
              Patellofemoral
            </Paragraph>
            <Image
              src={
                process.env.PUBLIC_URL +
                '/cscore/patellofemoral/' +
                operativeSide.toLowerCase() +
                '_operative_side/' +
                patello.medialLossText +
                '_' +
                patello.lateralLossText +
                '.png'
              }
              alt={'Patellofemoral Image'}
              preview={false}
              width="175px"
            ></Image>
            <CScoreBoneImageNumber
              leftPosition={32}
              value={opSideLeft ? patello.medialValue : patello.lateralValue}
              chartConfig={CConfig}
            />
            <CScoreBoneImageNumber
              leftPosition={103}
              value={opSideLeft ? patello.lateralValue : patello.medialValue}
              chartConfig={CConfig}
            />
          </Col>
          {/* ---- Tibiofemoral Image ---- */}
          <Col span={11}>
            <Text style={{ marginLeft: 45, fontSize: 16 }}>Tibiofemoral</Text>
            <Image
              src={
                process.env.PUBLIC_URL +
                '/cscore/tibiofemoral/' +
                operativeSide.toLowerCase() +
                '_operative_side/' +
                tibio.medialLossText +
                '_' +
                tibio.lateralLossText +
                '.png'
              }
              style={{ marginLeft: -10, marginTop: 7 }}
              alt={'Tibiofemoral Image'}
              preview={false}
              width="200px"
            ></Image>
            <CScoreBoneImageNumber
              leftPosition={opSideLeft ? 18 : 9}
              value={opSideLeft ? tibio.medialValue : tibio.lateralValue}
              chartConfig={CConfig}
            />
            <CScoreBoneImageNumber
              leftPosition={opSideLeft ? 134 : 125}
              value={opSideLeft ? tibio.lateralValue : tibio.medialValue}
              chartConfig={CConfig}
            />
          </Col>
        </Row>
        {/* We have two "pages" in one modal by toggling the content */}
        <InsightsModal
          modalOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          title={
            inContextPageShown
              ? 'Cartilage Loss Probability in Context'
              : 'Cartilage Loss Probability Definition and More Information'
          }
          switchButtonContent={
            inContextPageShown ? (
              // No Row object wrapper means right aligned button
              <Button
                type="primary"
                size="large"
                onClick={() => setInContextPageShown(!inContextPageShown)}
                style={{ margin: 10 }}
              >
                Definition & More Info
                <DoubleRightOutlined />
              </Button>
            ) : (
              // Wrapping button in Row object moves it to the left
              <Row>
                <Button
                  type="primary"
                  size="large"
                  onClick={() => setInContextPageShown(!inContextPageShown)}
                  style={{ margin: 10 }}
                >
                  <DoubleLeftOutlined />
                  Loss Probability in Context
                </Button>
              </Row>
            )
          }
        >
          {inContextPageShown ? (
            <CScoreInContextPage
              patello={patello}
              tibio={tibio}
              operativeSide={operativeSide}
              chartConfig={CConfig}
            />
          ) : (
            <CScoreDefinitionMoreInfoPage chartConfig={CConfig} />
          )}
        </InsightsModal>
      </Card>
    );
  } else {
    return <div />;
  }
}

export default CScore;
export type { BoneProps };
