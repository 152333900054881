import {
  Col,
  Empty,
  Menu,
  Row,
  Slider,
  Spin,
  SubMenuProps,
  Switch,
} from 'antd';
import { useAccessToken } from 'osep-react-antd';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import KneeBoneModelCanvas from '../components/Insights/KneeInsights/KneeBoneModelCanvas';
import BScore from '../components/Insights/KneeInsights/BScore/BScore';
import CScore from '../components/Insights/KneeInsights/CScore/CScore';
import ProcedureTime from '../components/Insights/KneeInsights/ProcedureTime';
import { TenantContext } from '../components/TenantContextProvider';
import useTreatmentPlanRequestQuery from '../hooks/queries/useTreatmentPlanRequestQuery';
import useSilentRequest from '../hooks/useSilentRequest';
import { createProcedureTimeChartConfig } from '../utils/chartConfigs';
import findLatestWorkflowExection from '../utils/findLatestWorkflowExecution';
import CenteredPageLayout from './CenteredPageLayout';
import BaseInsightsPage from './BaseInsightsPage';
function KneeInsightsPage() {
  const { treatmentPlanRequestId } = useParams();
  const [displaySettings, setDisplaySettings] = useState({
    showFemur: true,
    showFemurOsteophytes: true,
    showTibia: true,
    showTibiaOsteophytes: true,
    showMcl: true,
    showFibula: true,
    flexion: false,
    osteophytesOpacity: 100,
    showProcedureTime: true,
    showBScore: true,
    showCScore: true,
  });

  const {
    osteophytesOpacity,
    showFemur,
    showFemurOsteophytes,
    showTibia,
    showTibiaOsteophytes,
    showMcl,
    showFibula,
    flexion,
    showBScore,
    showCScore,
    showProcedureTime,
  } = displaySettings;

  const { data, isLoading } = useTreatmentPlanRequestQuery(
    treatmentPlanRequestId ?? ''
  );
  const silentRequest = useSilentRequest();
  const aquireAccessToken = useAccessToken(silentRequest);
  const [accessToken, setAccessToken] = useState<string>();

  useEffect(() => {
    aquireAccessToken().then(({ accessToken }) => setAccessToken(accessToken));
  }, [aquireAccessToken]);

  const latestWorkflowExecution = findLatestWorkflowExection(
    data?.workflowExecutions
  );
  const workflowResults = latestWorkflowExecution?.results;

  const tenantContext = useContext(TenantContext);
  const tenantId = tenantContext?.tenant?.id;

  const [openSubMenus, setOpenSubMenus] = useState([
    'bones',
    'osteophytes',
    'widgets',
  ]);

  const cementedTimeChartConfig = useMemo(() => {
    if (
      workflowResults?.surgicalTimeCemented?.movingStdDevLast10CasesInMinutes &&
      workflowResults.surgicalTimeCemented.movingMeanLast10CasesInMinutes
    ) {
      return createProcedureTimeChartConfig(
        workflowResults.surgicalTimeCemented
      );
    } else {
      return undefined;
    }
  }, [workflowResults]);

  const cementlessTimeChartConfig = useMemo(() => {
    if (
      workflowResults?.surgicalTimeCementless
        ?.movingStdDevLast10CasesInMinutes &&
      workflowResults.surgicalTimeCementless.movingMeanLast10CasesInMinutes
    ) {
      return createProcedureTimeChartConfig(
        workflowResults.surgicalTimeCementless
      );
    } else {
      return undefined;
    }
  }, [workflowResults]);

  if (isLoading || !tenantId) {
    return (
      <CenteredPageLayout>
        <Spin />
      </CenteredPageLayout>
    );
  }

  if (!workflowResults || !accessToken) {
    return (
      <CenteredPageLayout>
        <Empty />
      </CenteredPageLayout>
    );
  }

  const handleTitleClick: SubMenuProps['onTitleClick'] = (event) => {
    if (openSubMenus.find((sm) => sm === event.key)) {
      setOpenSubMenus(openSubMenus.filter((sm) => sm !== event.key));
    } else {
      setOpenSubMenus([...openSubMenus, event.key]);
    }
  };

  const operativeSideDisplay = data?.treatment?.operativeSide
    ? data?.treatment?.operativeSide.charAt(0).toUpperCase() +
      data?.treatment?.operativeSide.slice(1)
    : '';

  return (
    <BaseInsightsPage
      pagePath={'knee-insights'}
      boneCanvas={
        workflowResults?.surfaces ? (
          <KneeBoneModelCanvas
            tenantId={tenantId}
            accessToken={accessToken}
            surfaces={workflowResults.surfaces}
            showFemur={showFemur}
            showFemurOsteophytes={showFemurOsteophytes}
            showTibia={showTibia}
            showTibiaOsteophytes={showTibiaOsteophytes}
            showMcl={showMcl}
            showFibula={showFibula}
            flexion={flexion}
            osteophytesOpacity={osteophytesOpacity / 100}
            style={{ zIndex: 500 }}
          />
        ) : (
          <Empty />
        )
      }
      boneControls={
        <>
          <Menu.Item>
            <Row>
              <Col span={8} style={{ fontSize: '0.92rem' }}>
                Femur
              </Col>
              <Col>
                <Switch
                  checked={showFemur}
                  onChange={() =>
                    setDisplaySettings({
                      ...displaySettings,
                      showFemur: !showFemur,
                    })
                  }
                />
              </Col>
            </Row>
          </Menu.Item>
          <Menu.Item>
            <Row>
              <Col span={8} style={{ fontSize: '0.92rem' }}>
                Tibia
              </Col>
              <Col>
                <Switch
                  checked={showTibia}
                  onChange={() =>
                    setDisplaySettings({
                      ...displaySettings,
                      showTibia: !showTibia,
                    })
                  }
                />
              </Col>
            </Row>
          </Menu.Item>
          <Menu.Item>
            <Row>
              <Col span={8} style={{ fontSize: '0.92rem' }}>
                Fibula
              </Col>
              <Col>
                <Switch
                  checked={showFibula}
                  onChange={() =>
                    setDisplaySettings({
                      ...displaySettings,
                      showFibula: !showFibula,
                    })
                  }
                />
              </Col>
            </Row>
          </Menu.Item>
        </>
      }
      otherControls={
        <>
          <Menu.SubMenu
            title="Osteophytes"
            key="osteophytes"
            onTitleClick={handleTitleClick}
          >
            <Menu.Item>
              <Row>
                <Col span={8} style={{ fontSize: '0.92rem' }}>
                  Femur
                </Col>
                <Col>
                  <Switch
                    checked={showFemurOsteophytes}
                    onChange={() =>
                      setDisplaySettings({
                        ...displaySettings,
                        showFemurOsteophytes: !showFemurOsteophytes,
                      })
                    }
                  />
                </Col>
              </Row>
            </Menu.Item>
            <Menu.Item>
              <Row>
                <Col span={8} style={{ fontSize: '0.92rem' }}>
                  Tibia
                </Col>
                <Col>
                  <Switch
                    checked={showTibiaOsteophytes}
                    onChange={() =>
                      setDisplaySettings({
                        ...displaySettings,
                        showTibiaOsteophytes: !showTibiaOsteophytes,
                      })
                    }
                  />
                </Col>
              </Row>
            </Menu.Item>
            <Menu.Item>
              <Row>
                <Col span={23} style={{ fontSize: '0.92rem' }}>
                  <Slider
                    value={osteophytesOpacity}
                    min={0}
                    max={100}
                    step={1}
                    tooltip={{ formatter: (value) => `${value}%` }}
                    onChange={(value) =>
                      setDisplaySettings({
                        ...displaySettings,
                        osteophytesOpacity: value,
                      })
                    }
                  />
                </Col>
              </Row>
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.Item>
            <Row>
              <Col span={8} style={{ fontSize: '0.92rem' }}>
                Extension
              </Col>
              <Col>
                <Switch
                  checked={flexion}
                  onChange={() =>
                    setDisplaySettings({
                      ...displaySettings,
                      flexion: !flexion,
                    })
                  }
                />
              </Col>
              <Col offset={3} span={8} style={{ fontSize: '0.92rem' }}>
                Flexion
              </Col>
            </Row>
          </Menu.Item>
          <Menu.Item>
            <Row>
              <Col span={8} style={{ fontSize: '0.92rem' }}>
                MCL
              </Col>
              <Col>
                <Switch
                  checked={showMcl}
                  onChange={() =>
                    setDisplaySettings({
                      ...displaySettings,
                      showMcl: !showMcl,
                    })
                  }
                />
              </Col>
            </Row>
          </Menu.Item>
        </>
      }
      widgets={
        <>
          <ProcedureTime
            workflowResults={workflowResults}
            style={{
              marginBottom: 24,
              zIndex: 1000,
            }}
            cementedTimeChartConfig={cementedTimeChartConfig}
            cementlessTimeChartConfig={cementlessTimeChartConfig}
            widgetVisible={showProcedureTime}
          />
          <BScore
            style={{ marginBottom: 24, zIndex: 1000 }}
            workflowResults={workflowResults}
            widgetVisible={showBScore}
          />
          <CScore
            style={{ paddingBottom: 24, zIndex: 1000 }}
            workflowResults={workflowResults}
            operativeSide={operativeSideDisplay}
            widgetVisible={showCScore}
          />
        </>
      }
      widgetControls={
        <>
          {workflowResults.surgicalTimeCemented &&
            workflowResults.surgicalTimeCementless && (
              <Menu.Item>
                <Row>
                  <Col span={19} style={{ fontSize: '0.92rem' }}>
                    Predictive Time
                  </Col>
                  <Col>
                    <Switch
                      checked={showProcedureTime}
                      onChange={() =>
                        setDisplaySettings({
                          ...displaySettings,
                          showProcedureTime: !showProcedureTime,
                        })
                      }
                    />
                  </Col>
                </Row>
              </Menu.Item>
            )}
          {workflowResults.bScore && (
            <Menu.Item>
              <Row>
                <Col span={19} style={{ fontSize: '0.92rem' }}>
                  B-Score
                </Col>
                <Col>
                  <Switch
                    checked={showBScore}
                    onChange={() =>
                      setDisplaySettings({
                        ...displaySettings,
                        showBScore: !showBScore,
                      })
                    }
                  />
                </Col>
              </Row>
            </Menu.Item>
          )}
          {workflowResults.cScores && (
            <Menu.Item>
              <Row>
                <Col span={19} style={{ fontSize: '0.92rem' }}>
                  C-Score
                </Col>
                <Col>
                  <Switch
                    checked={showCScore}
                    onChange={() =>
                      setDisplaySettings({
                        ...displaySettings,
                        showCScore: !showCScore,
                      })
                    }
                  />
                </Col>
              </Row>
            </Menu.Item>
          )}
        </>
      }
    />
  );
}

export default KneeInsightsPage;
