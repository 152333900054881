import { Typography, Modal } from 'antd';
const { Title } = Typography;

interface Props {
  modalOpen: boolean;
  onClose: any;
}
const infotext = (
  <span>
    The Hounsfield Unit (HU) scale is a quantification of radio density (opacity
    to X-rays) based on calibrating to air (-1000 HU) and water (0 HU). Whilst
    HUs have been shown to be correlated with bone mineral density, they are
    also dependent on CT equipment and scanning protocol parameters. It should
    be noted that HU threshold values are based on a general population and are
    not adjusted for patient sex or age.
  </span>
);

function BoneQualityThresholdInfoModal({ modalOpen, onClose }: Props) {
  return (
    <Modal
      title={<Title level={3}>Bone Quality Threshold</Title>}
      open={modalOpen}
      onCancel={onClose}
      footer={null}
      width={1000}
    >
      <Title level={5} style={{ fontWeight: 375 }}>
        {infotext}
      </Title>
    </Modal>
  );
}

export default BoneQualityThresholdInfoModal;
