import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  TreatmentPlanRequestEntity,
  TreatmentPlanRequestsApi,
} from 'solid-workflow-service-typescript-axios';

import { TREATMENT_PLAN_REQUESTS_QUERY_KEY } from '../../utils/consts';
import useSolidWorkflowService from '../useApiConfig';
import { TenantContext } from '../../components/TenantContextProvider';
import { useContext } from 'react';

interface PatientConsentGrants {
  [treatmentPlanRequestId: string]: boolean;
}

function usePatientConsentsMutation() {
  const { createApiConfig } = useSolidWorkflowService();
  const tenantContext = useContext(TenantContext);
  const tenantId = tenantContext?.tenant?.id;

  const queryClient = useQueryClient();

  const grantPatientConsents = (patientConsentGrants: PatientConsentGrants) =>
    createApiConfig()
      .then((apiConfig) => new TreatmentPlanRequestsApi(apiConfig))
      .then(async (api) => {
        const treatmentPlanRequestIds = Object.keys(patientConsentGrants);

        const consents = treatmentPlanRequestIds.map(
          (treatmentPlanRequestId) => ({
            treatmentPlanRequestId,
            patientConsentGranted: patientConsentGrants[treatmentPlanRequestId]
              ? true
              : false,
          })
        );

        await api.treatmentPlanRequestsPatientConsentsPut(
          tenantId ?? '',
          consents
        );

        return treatmentPlanRequestIds;
      });

  return useMutation(grantPatientConsents, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [TREATMENT_PLAN_REQUESTS_QUERY_KEY],
      });
    },
    onMutate: async (consents) => {
      await queryClient.cancelQueries({
        queryKey: [TREATMENT_PLAN_REQUESTS_QUERY_KEY],
      });

      const previousTreatmentPlanRequests = queryClient.getQueriesData<{
        [id: string]: TreatmentPlanRequestEntity;
      }>([TREATMENT_PLAN_REQUESTS_QUERY_KEY]);

      queryClient.setQueriesData<{
        [id: string]: TreatmentPlanRequestEntity;
      }>([TREATMENT_PLAN_REQUESTS_QUERY_KEY], (old) => {
        if (!old) {
          return old;
        }

        return Object.keys(old).reduce((previous, current) => {
          return {
            ...previous,
            [current]: {
              ...old[current],
              patientConsentGranted:
                current in consents
                  ? consents[current]
                  : old[current].patientConsentGranted,
            },
          };
        }, {});
      });

      return { previousTreatmentPlanRequests };
    },
  });
}

export default usePatientConsentsMutation;
