import { useMutation } from '@tanstack/react-query';
import { useContext } from 'react';
import { DataExtractionApi } from 'solid-workflow-service-typescript-axios';
import { TenantContext } from '../../components/TenantContextProvider';
import useSolidWorkflowService from '../useApiConfig';

function useStartDataExtractionMutation() {
  const tenantContext = useContext(TenantContext);
  const tenantId = tenantContext?.tenant?.id ?? '';

  const { createApiConfig } = useSolidWorkflowService();

  const mutate = () =>
    createApiConfig()
      .then((apiConfig) => new DataExtractionApi(apiConfig))
      .then(async (api) => {
        await api.dataExtractionPost(tenantId);
      });

  return useMutation(mutate);
}

export default useStartDataExtractionMutation;
