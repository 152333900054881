import { Center, OrbitControls, PerspectiveCamera } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';

import { CSSProperties, Suspense, useRef } from 'react';
import {
  WorkflowResultsSurfaces,
  WorkflowResultsSurfacesBoneQualityThresholds,
} from 'solid-workflow-service-typescript-axios';
import BoneQualityModel from './BoneQualityModel';
import BoneModelLoadingIndicator from '../BoneModelLoadingIndicator';

interface Props {
  surfaces: WorkflowResultsSurfaces;
  boneQualityThresholds: WorkflowResultsSurfacesBoneQualityThresholds[];
  accessToken: string;
  tenantId: string;
  showTibia: boolean;
  showFibula: boolean;
  thresholdNumber: number;
  style?: CSSProperties;
}

function BoneQualityModelCanvas({
  tenantId,
  surfaces,
  boneQualityThresholds,
  accessToken,
  showTibia,
  showFibula,
  thresholdNumber,
  style,
}: Props) {
  const orbitRef = useRef<any>(null);
  const handleDoubleClick = () => {
    if (orbitRef.current) {
      orbitRef.current.reset();
    }
  };

  return (
    <Canvas onDoubleClick={handleDoubleClick} style={style}>
      <PerspectiveCamera
        makeDefault
        position={[0, 0, 1050]}
        near={1}
        far={2000}
        zoom={2.3}
      >
        <directionalLight
          position={[1, 1, 2]}
          color="#ffffff"
          intensity={0.75}
        />
        <pointLight position={[0, -25, 10]} color="#ffffff" intensity={0.2} />
      </PerspectiveCamera>
      <ambientLight color={'#ffffff'} intensity={0.08} />
      <Suspense fallback={<BoneModelLoadingIndicator />}>
        <Center>
          <OrbitControls maxDistance={400} minDistance={10} ref={orbitRef} />
          <BoneQualityModel
            tenantId={tenantId}
            surfaces={surfaces}
            boneQualityThresholds={boneQualityThresholds}
            accessToken={accessToken}
            showTibia={showTibia}
            showFibula={showFibula}
            thresholdNumber={thresholdNumber}
          />
        </Center>
      </Suspense>
    </Canvas>
  );
}

export default BoneQualityModelCanvas;
