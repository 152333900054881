import { MsalAuthenticationResult } from '@azure/msal-react';
import { useEffect } from 'react';
import { notifyUser } from 'osep-react-antd';

function MsalAuthenticationError({ error }: MsalAuthenticationResult) {
  useEffect(() => {
    notifyUser('error', 'Unable to authenticate');
    console.error(error);
  }, [error]);

  return <></>;
}

export default MsalAuthenticationError;
