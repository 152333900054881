import { SilentRequest } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { useMemo } from 'react';
import config from '../config';

function useSilentRequest(): SilentRequest {
  const { accounts } = useMsal();
  const account = accounts[0];

  return useMemo(
    () => ({
      account,
      scopes: config.scopes,
      authority: `https://login.microsoftonline.com/${config.tenantId}`,
    }),
    [account]
  );
}

export default useSilentRequest;
