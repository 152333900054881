import { Center, OrbitControls, PerspectiveCamera } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';

import { CSSProperties, Suspense, useRef } from 'react';
import { WorkflowResultsSurfaces } from 'solid-workflow-service-typescript-axios';
import BoneModelLoadingIndicator from '../BoneModelLoadingIndicator';
import KneeBoneModels from './KneeBoneModels';

interface Props {
  surfaces: WorkflowResultsSurfaces;
  accessToken: string;
  osteophytesOpacity: number;
  showTibia: boolean;
  showTibiaOsteophytes: boolean;
  showFemur: boolean;
  showFemurOsteophytes: boolean;
  showMcl: boolean;
  showFibula: boolean;
  flexion: boolean;
  tenantId: string;
  style?: CSSProperties;
}

function KneeBoneModelCanvas({
  tenantId,
  surfaces,
  accessToken,
  osteophytesOpacity,
  showTibia,
  showFemur,
  showFemurOsteophytes,
  showTibiaOsteophytes,
  showMcl,
  showFibula,
  flexion,
  style,
}: Props) {
  const orbitRef = useRef<any>(null);
  const handleDoubleClick = () => {
    if (orbitRef.current) {
      orbitRef.current.reset();
    }
  };

  return (
    <Canvas onDoubleClick={handleDoubleClick} style={style}>
      <PerspectiveCamera
        makeDefault
        position={[0, 0, 300]}
        near={0.1}
        far={1000}
      >
        <directionalLight
          position={[1, 1, 2]}
          color="#ffffff"
          intensity={0.75}
        />
        <pointLight position={[0, -25, 10]} color="#ffffff" intensity={0.3} />
      </PerspectiveCamera>
      <ambientLight color={'#ffffff'} intensity={0.1} />
      <Suspense fallback={<BoneModelLoadingIndicator />}>
        <Center>
          <OrbitControls maxDistance={600} minDistance={0} ref={orbitRef} />
          <KneeBoneModels
            tenantId={tenantId}
            surfaces={surfaces}
            accessToken={accessToken}
            showFemur={showFemur}
            showFemurOsteophytes={showFemurOsteophytes}
            showTibia={showTibia}
            showTibiaOsteophytes={showTibiaOsteophytes}
            showMcl={showMcl}
            showFibula={showFibula}
            flexion={flexion}
            osteophytesOpacity={osteophytesOpacity}
          />
        </Center>
      </Suspense>
    </Canvas>
  );
}

export default KneeBoneModelCanvas;
