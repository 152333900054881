import { Col, Empty, Menu, Row, Spin, Switch } from 'antd';
import { useAccessToken } from 'osep-react-antd';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TenantContext } from '../components/TenantContextProvider';
import useTreatmentPlanRequestQuery from '../hooks/queries/useTreatmentPlanRequestQuery';
import useSilentRequest from '../hooks/useSilentRequest';
import findLatestWorkflowExection from '../utils/findLatestWorkflowExecution';
import CenteredPageLayout from './CenteredPageLayout';
import FullLegModelCanvas from '../components/Insights/FullLegInsights/FullLegModelCanvas';
import ArithmeticHipKneeAnkle from '../components/Insights/FullLegInsights/ArithmeticHipKneeAnkle';
import BaseInsightsPage from './BaseInsightsPage';

function FullLegInsightsPage() {
  const { treatmentPlanRequestId } = useParams();

  const [displaySettings, setDisplaySettings] = useState({
    showFemur: true,
    showTibia: true,
    showAHKA: true,
  });
  const { showFemur, showTibia, showAHKA } = displaySettings;

  const { data, isLoading } = useTreatmentPlanRequestQuery(
    treatmentPlanRequestId ?? ''
  );
  const silentRequest = useSilentRequest();
  const aquireAccessToken = useAccessToken(silentRequest);
  const [accessToken, setAccessToken] = useState<string>();

  useEffect(() => {
    aquireAccessToken().then(({ accessToken }) => setAccessToken(accessToken));
  }, [aquireAccessToken]);

  const latestWorkflowExecution = findLatestWorkflowExection(
    data?.workflowExecutions
  );
  const workflowResults = latestWorkflowExecution?.results;

  const tenantContext = useContext(TenantContext);
  const tenantId = tenantContext?.tenant?.id;

  if (isLoading || !tenantId) {
    return (
      <CenteredPageLayout>
        <Spin />
      </CenteredPageLayout>
    );
  }

  if (!workflowResults?.fullLegArithmeticHipKneeAnkle || !accessToken) {
    return (
      <CenteredPageLayout>
        <Empty />
      </CenteredPageLayout>
    );
  }

  return (
    <BaseInsightsPage
      pagePath={'full-leg-insights'}
      boneCanvas={
        workflowResults?.surfaces ? (
          <FullLegModelCanvas
            tenantId={tenantId}
            accessToken={accessToken}
            surfaces={workflowResults.surfaces}
            showFemur={showFemur}
            showTibia={showTibia}
            style={{ zIndex: 500 }}
          />
        ) : (
          <Empty />
        )
      }
      boneControls={
        <>
          <Menu.Item>
            <Row>
              <Col span={8} style={{ fontSize: '0.92rem' }}>
                Femur
              </Col>
              <Col>
                <Switch
                  checked={showFemur}
                  onChange={() =>
                    setDisplaySettings({
                      ...displaySettings,
                      showFemur: !showFemur,
                    })
                  }
                />
              </Col>
            </Row>
          </Menu.Item>
          <Menu.Item>
            <Row>
              <Col span={8} style={{ fontSize: '0.92rem' }}>
                Tibia
              </Col>
              <Col>
                <Switch
                  checked={showTibia}
                  onChange={() =>
                    setDisplaySettings({
                      ...displaySettings,
                      showTibia: !showTibia,
                    })
                  }
                />
              </Col>
            </Row>
          </Menu.Item>
        </>
      }
      widgetControls={
        <Menu.Item>
          <Row>
            <Col span={19} style={{ fontSize: '0.92rem' }}>
              Hip-Knee-Ankle (aHKA)
            </Col>
            <Col>
              <Switch
                checked={showAHKA}
                onChange={() =>
                  setDisplaySettings({
                    ...displaySettings,
                    showAHKA: !showAHKA,
                  })
                }
              />
            </Col>
          </Row>
        </Menu.Item>
      }
      widgets={
        <ArithmeticHipKneeAnkle
          style={{ marginBottom: 24, zIndex: 1000 }}
          workflowResults={workflowResults}
          widgetVisible={showAHKA}
        />
      }
    />
  );
}

export default FullLegInsightsPage;
