import { Typography, Col, Row, Space } from 'antd';
import React from 'react';
import ReactPlayer from 'react-player';

const { Title, Paragraph } = Typography;

function BScoreDefinitionMoreInfoPage() {
  return (
    <Row>
      <Col span={12}>
        <Space direction="vertical">
          <Title level={5}>
            A 3D femur bone has consistent shape change in knee osteoarthritis
            (OA), recorded as B-score
          </Title>
          <Paragraph>
            B-score gives us a continuous, quantitative variable, which can be
            used to quantify amount of OA damage in the knee, and also to
            measure progression in longitudinal studies.
          </Paragraph>
          <Paragraph>
            As OA progresses, each knee bone exhibits a characteristic shape
            change, involving the growth of osteophytes around the cartilage
            plates, and a spreading and flattening of the subchondral bone
            surface
          </Paragraph>
          <Paragraph>
            Femur shape change is used for B-score, as it undergoes changes more
            than the tibia and patella.
          </Paragraph>
          <Paragraph>
            <ul>
              B-score is the distance along a vector which captures this 'OA'
              shape change. B-score is recorded as a z-score, similar to the
              T-score in osteoporosis:
              <li>
                It is in units of SD of a healthy population, with 0 defined as
                the mean of a healthy population
              </li>
              <li>Values of -2 to +2 represent the healthy population</li>
              <li>Values above +2 fall beyond the healthy population</li>
            </ul>
          </Paragraph>
        </Space>
      </Col>
      <Col span={12}>
        <ReactPlayer
          className="react-player fixed-bottom"
          url={process.env.PUBLIC_URL + '/bscore/video/b-score-anim.MP4'}
          width="95%"
          height="95%"
          controls={true}
        />
      </Col>
    </Row>
  );
}

export default BScoreDefinitionMoreInfoPage;
