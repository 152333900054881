import { Moment } from 'moment';
import {
  DateTimeFilterType,
  Filters,
} from '../../hooks/queries/useTreatmentPlanRequestsQuery';

export type Action =
  | { type: 'set-date-time-filter-type'; value: DateTimeFilterType }
  | { type: 'set-date-time-range'; value: { start: Moment; end: Moment } }
  | { type: 'set-request-states'; value: string[] };

export function filterReducer(state: Filters, action: Action): Filters {
  switch (action.type) {
    case 'set-date-time-filter-type':
      return {
        ...state,
        dateTimeFilterType: action.value,
      };
    case 'set-date-time-range':
      return {
        ...state,
        dateTimeStart: action.value.start,
        dateTimeEnd: action.value.end,
      };
    case 'set-request-states':
      return {
        ...state,
        requestStates: action.value,
      };
    default:
      return state;
  }
}

export default filterReducer;
