import { useContext } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { TenantContext } from './TenantContextProvider';

const TenantGate: React.FC = ({ children }) => {
  const { tenantId } = useParams();
  const tenantContext = useContext(TenantContext);

  if (!tenantContext) {
    return <></>;
  }

  const { availableTenants, setTenant, tenant } = tenantContext;

  if (!tenantId || !availableTenants.find((t) => t.id === tenantId)) {
    // Tenant from URL param doesn't exists/is not available to user.
    return <Navigate to="/" />;
  } else if (tenantId !== tenant?.id) {
    // Switch to the tenant from the URL
    setTenant(tenantId);
  }

  return <>{children}</>;
};

export default TenantGate;
